import React, { useEffect, useState } from "react";
import Header from "../../component/header/header";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  // const [neckImages, setNeckImages] = useState<any>([]);
  const data = location?.state?.data;

  // useEffect(() => {
  //   let imgData = data?.neck_type;

  //   if (imgData?.startsWith("[") && imgData?.endsWith("]")) {
  //     setNeckImages(JSON.parse(imgData));
  //   } else {
  //     setNeckImages([imgData]);
  //   }
  // }, []);

  return (
    <div>
      <Header hide={true} show={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="overFlow-Box1">
        <div className="overFlow-Box2">
          <div className="white-Card" style={{ borderRadius: 0 }}>
            <Container>
              <div style={{ display: "flex" }}>
                <div
                  className="profile-title"
                  onClick={() => navigate(`/usr/${id}`)}
                >
                  BACK
                </div>
                &nbsp;
                <div className="profile-title">ORDER DETAILS</div>
                &nbsp;
                <div className="profile-title">
                  {data?.status == 1
                    ? "PENDING"
                    : data?.status == 2
                    ? "DELIVERED"
                    : data?.status == 3
                    ? "PROGRESS"
                    : ""}
                </div>
              </div>
              <br />
              <br />
              <br />
              <Row>
                <Col md={6}>
                  <div>
                    <div className="screen-Txt2">PRODUCT</div>
                    <br />
                    <img
                      className="MeasurmentScreen-Img"
                      style={{ objectFit: "contain", height: "350px" }}
                      src={data?.productImage}
                      alt=""
                    />
                    <br />
                    <br />
                    <div className="screen-Txt2">
                      <b>Product Name:</b> {data?.product_Ordered.product_name}
                      <br />
                      <b>Price:</b> {data?.product_Ordered.price}
                      <br />
                      <b>Information:</b> {data?.product_Ordered.information}
                      <br />
                      <b>Description:</b> {data?.product_Ordered.description}
                      <br />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <Col md={6}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className="screen-Txt2">PRODUCT TYPE</div>
                        <br />
                        <img
                          className="MeasurmentScreen-Img"
                          style={{ objectFit: "contain", height: "150px" }}
                          src={data?.productType}
                          alt=""
                        />
                        <br />
                        <br />
                        <div className="screen-Txt2">
                          <b>Product Type Name: </b>
                          {data?.productTypeName}
                        </div>
                      </div>

                      <div className="screen-Txt2">
                        <div>MEASUREMENT</div>
                        <br />
                        <div style={{ display: "flex" }}>
                          <div>
                            <b>neck:</b> {data?.neck}
                            <br />
                            <b>length:</b> {data?.length}
                            <br />
                            <b>chest:</b> {data?.neck}
                            <br />
                            <b>hip:</b> {data?.upper_hip}
                          </div>
                          &nbsp; &nbsp; &nbsp;
                          <div>
                            <b>sleeve_Length:</b>
                            {data?.sleeve_Length}
                            <br />
                            <b>upper_hip:</b> {data?.upper_hip}
                            <br />
                            <b>meterial:</b> {data?.meterial}
                            <br />
                            <b>shoulder:</b> {data?.shoulder}
                            <br />
                            <b>sleeve_Opening:</b> {data?.sleeve_Opening}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <br />
                  <Col md={6}>
                    <div>
                      <div className="screen-Txt2">TYPES</div>
                      <br />

                      {data?.neck_type &&
                        data?.neck_type?.length &&
                        data?.neck_type?.map((img: any, index: any) => {
                          return (
                            <img
                              key={index}
                              className="MeasurmentScreen-Img"
                              style={{
                                objectFit: "contain",
                                height: "100px",
                              }}
                              src={img}
                              alt={`Neck Type ${index}`}
                            />
                          );
                        })}
                    </div>
                  </Col>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
