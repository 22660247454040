import { Button, Col, Form, message, notification } from "antd";
import CreditCard from "../assets/images/creditcard.png";
import DebitCard from "../assets/images/debitcard.png";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import { API } from "../config/API";
import { useEffect, useState } from "react";
import { GET, POST } from "../utils/apiRequest";
import { useSelector } from "react-redux";
function PaymentMethod({ totalBillAmount }: any) {
  const { productId, type } = useParams();
  const { accessoriesItem, measurements, productType, products } = useSelector(
    (state: any) => {
      return state;
    }
  );

  const user = useSelector((state: any) => state.User);

  const [loading, setIsloading] = useState(false);
  const [product, setProduct] = useState<any>({});
  const [total, setTotal] = useState<any>();
  const measurement = measurements?.measurement;
  const navigate = useNavigate();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let url = API.GET_PRODUCT_BY_ID + productId;
      const data: any = await GET(url, null);
      if (data != null) {
        setProduct(data);
      }
    } catch (err) {}
  };
  const onFinish = async () => {
    setIsloading(true);
    try {
      let image = accessoriesItem?.options?.map((item: any) => item.image);
      let obj = {
        adminId: user?.user?.id,
        productId: measurement?.productId,
        productName: products?.products?.product_name,
        meterial: measurement?.meterial,
        length: measurement?.length,
        shoulder: measurement?.shoulder,
        chest: measurement?.chest,
        upper_hip: measurement?.upper_hip,
        hip: measurement?.hip,
        sleeve_Opening: measurement?.sleeve_Opening,
        sleeve_Length: measurement?.sleeve_Length,
        embriodery: measurement?.embriodery,
        neck: measurement?.neck,
        embroidary: measurement?.embroidary,
        delivery_Date: new Date(),
        neck_type: image,
        productImage: products?.products?.image,
        productType: productType?.productType,
        productTypeName: productType?.productTypeName,
        payment_amount: totalBillAmount,
        payment_option: total,
      };

      let url = API.ADD_PRODUCT_ORDER;

      let response: any = await POST(url, obj);
      setIsloading(false);
      if (response) {
        setIsloading(false);
        notification.success({
          message: "Product Added ",
          description: "Successfully Added The Product",
        });
        navigate(
          `/usr/product/${productId}/type/${type}/measurment/summary/accessories/billing/invoice`
        );
      } else {
        setIsloading(false);
        notification.error({
          message: "Failed",
          description: "Error Found.",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlePaymentChoice(paymentMethod: any) {
    if (paymentMethod === "cash") {
      message.success("Cash payment selected. Thank you!");
    } else if (paymentMethod === "credit_card") {
      message.success("Credit card payment selected. Thank you!");
    }
    setTotal(paymentMethod);
  }
  return (
    <>
      <Form onFinish={onFinish}>
        <Col md={24}>
          <div className="payment-Box1">
            <div className="payment-Box2">PAYMENT METHOD</div>
            <div className="payment-Box3">
              <Col md={24}>
                <Button
                  block
                  size="large"
                  className="payment_DebitBtn"
                  onClick={() => handlePaymentChoice("credit_card")}
                >
                  CREDIT CARD
                  <span>
                    <img src={CreditCard} alt="" />
                  </span>
                </Button>
              </Col>
              <br />
              <Col md={24}>
                <Button
                  block
                  className="payment_CreditBtn"
                  onClick={() => handlePaymentChoice(`cash`)}
                >
                  CASH
                  <span>
                    <img src={DebitCard} alt="" />
                  </span>
                </Button>
              </Col>
              <br />
              <br />
              <br />
              <Col md={{ span: 12, offset: 12 }}>
                <Button
                  block
                  size="large"
                  className="primary_bttn3"
                  loading={loading}
                  htmlType="submit"
                >
                  PAY
                </Button>
              </Col>
            </div>
          </div>
        </Col>
      </Form>
    </>
  );
}

export default PaymentMethod;
