import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosCamera } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/user.png";
import Header from "../../component/header/header";
import ImagePicker from "../../component/imagePIcker";
import LoadingBox from "../../component/loadingBox";
import { API } from "../../config/API";
import CountryCode from "../../config/countryCode.json";
import { login } from "../../redux/slice/userSlice";
import { PUT } from "../../utils/apiRequest";
import TextArea from "antd/es/input/TextArea";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function UpdateProfile(props: any) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.User);
  console.log(user);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(user?.image);
  const [toggle, toggleModal] = useState(false);

  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let url = API.UPDATE_PROFILE + id;
      let body = {
        name: val?.name,
        phone_no: val?.phonenumber,
        countrycode: val.code,
        email: val.email,
        address: val.address,
        image: image,
      };
      const data: any = await PUT(url, body);
      if (data?.status === true) {
        message.success(data.message);
        dispatch(login(data?.data));
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const handleImageUpdated = (val: any) => {
    setImage(val);
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 85, borderRadius: 30 }} showSearch={true}>
        {CountryCode.map((item: any) => (
          <Select.Option key={item} value={item.dial_code}>
            {item.dial_code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  return (
    <>
      <Header hide={true} show={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Container>
        <div className="updateProfile-Txt1">
          {t("updateProfileScreen.title.heading")}
        </div>
        <Form
          onFinish={onFinish}
          initialValues={{
            code: "+91",
            name: user?.name,
            address: user?.address,
            phonenumber: user?.phone_no,
            email: user?.email,
          }}
        >
          <br />
          <br />
          <Row justify={"center"} align={"middle"}>
            <Col md={6}>
              <Col md={16}>
                <div className="updateProfile-Box1">
                  <div className="updateProfile-Box2">
                    {image ? (
                      <>
                        <img className="updateProfile-Img" src={image} alt="" />
                        <IoIosCamera
                          className="profile-edit"
                          color="#fff"
                          size={45}
                          onClick={() => toggleModal(true)}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="updateProfile-Img"
                          src={Avatar}
                          alt=""
                          onClick={() => toggleModal(true)}
                        />
                        <IoIosCamera
                          className="profile-edit"
                          color="#fff"
                          size={45}
                          onClick={() => toggleModal(true)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Col>
            <Col md={{ span: 8 }} xs={24} sm={24}>
              <Col md={24}>
                <label className="formLabel" style={{ paddingLeft: 5 }}>
                  {t("loginScreen.title.input_name")}
                </label>
                <Form.Item name={"name"}>
                  <Input
                    size="large"
                    style={{
                      border: "1px solid gray",
                      borderRadius: 30,
                      height: 48,
                      // paddingTop: 5,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={24}>
                <label className="formLabel" style={{ paddingLeft: 5 }}>
                  {t("loginScreen.title.input_phone")}
                </label>

                <Form.Item
                  name={"phonenumber"}
                  style={{
                    border: "1px solid gray",
                    borderRadius: 30,
                    height: 48,
                    // paddingTop: 5,
                  }}
                >
                  <Input
                    addonBefore={Selector}
                    type="number"
                    bordered={false}
                    size="large"
                    style={{
                      height: 48,
                      paddingTop: 5,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={24}>
                <label className="formLabel" style={{ paddingLeft: 5 }}>
                  {t("loginScreen.title.input_mail")}
                </label>
                <Form.Item name={"email"}>
                  <Input
                    size="large"
                    style={{
                      border: "1px solid gray",
                      borderRadius: 30,
                      height: 48,
                      // paddingTop: 5,
                    }}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col md={{ span: 8, offset: 1 }} xs={24} sm={24}>
              <Col md={24}>
                <label className="formLabel" style={{ paddingLeft: 5 }}>
                  {t("loginScreen.title.input_address")}
                </label>
                <Form.Item name={"address"}>
                  <TextArea
                    rows={6}
                    size="large"
                    style={{
                      border: "1px solid gray",
                      borderRadius: 30,
                      paddingLeft: 20,
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={{ span: 16, offset: 8 }}>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    className="primary_bttn1"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("updateProfileScreen.title.UPDATE")}
                  </Button>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
        <ImagePicker
          open={toggle}
          modalClose={() => toggleModal(false)}
          onImageUpdated={(data: any) => handleImageUpdated(data)}
        />
      </Container>
    </>
  );
}

export default UpdateProfile;
