import { Col, Row } from "react-bootstrap";

function Order() {
  const data = [
    { id: 1, name: "READY TO PICK", total: "34" },
    { id: 2, name: "WORKING", total: "42" },
    { id: 3, name: "BALANCE", total: "52" },
  ];
  return (
    <>
      <div className="white-Card">
        <div>
          <div className="dashBoard-Txt1">ORDERS</div>
          <div>
            <br />

            {data?.map((item: any, index: any) => {
              return (
                <>
                  <Row className="align-items-center">
                    <Col
                      md={6}
                      className="dashBoard-Txt5"
                      style={{ paddingTop: 14 }}
                    >
                      {item?.name}
                    </Col>
                    <Col md={{ span: 2, offset: 4 }}>
                      <div className="order-Box2">
                        <div className="dashBoard-Txt6">{item?.total}</div>
                      </div>
                    </Col>
                  </Row>
                  {index < data.length - 1 && <hr />}
                </>
              );
            })}
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Order;
