import { Button, Form, Input, Select, message, notification } from "antd";
import { InputOTP } from "antd-input-otp";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../../config/API";
import CountryCode from "../../../config/countryCode.json";
import { Auth } from "../../../config/fireBase";
import { login } from "../../../redux/slice/userSlice";
import { POST } from "../../../utils/apiRequest";

function Existing(props: any) {
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setVerification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>();
  const [existing, setExisting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      setData(val);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {
        size: "invisible",
      });
      let phone = `${val.code}${val.phonenumber}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );

      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setVerification(true);
        setIsLoading(false);
      } else {
        setError("Verification Failed");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const verifyOtp = async (val: any) => {
    try {
      setIsLoading(true);
      let otp = val.otp.join("");
      let verify = await autho.confirm(otp);
      if (verify?.user?.phoneNumber) {
        onLogin();
      } else {
        message.error("Incorrect OTP");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onLogin = async () => {
    try {
      let url = API.LOGIN;
      let body = {
        name: data?.name,
        phone_no: data?.phonenumber,
      };
      const resp: any = await POST(url, body);
      if (resp.status === true) {
        setIsLoading(true);
        message.success(resp?.message);
        dispatch(login(resp?.data));
        navigate(
          resp?.data?.mobileverified && resp?.data?.role === "admin"
            ? "/Auth"
            : `/usr/${user?.id}`
        );
      } else {
        notification.error({
          message: resp?.message,
        });
        setError(resp?.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select
        style={{ height: 54, width: 85, marginBottom: 3 }}
        showSearch={true}
      >
        {CountryCode.map((item: any) => (
          <Select.Option key={item} value={item.dial_code}>
            {item.dial_code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  return (
    <>
      {props?.open && (
        <Col md={12} lg={12} xl={12} xxl={{ span: 10, offset: 2 }}>
          <div className="LoginModal_Box1">
            <div className="LoginModal_Box2">
              <div className="loginScreen_Box3">
                <div className="loginModal_Txt1">
                  {t("loginScreen.title.existing_customer")}
                </div>
                <div>
                  <RxCross2
                    cursor={"pointer"}
                    size={34}
                    onClick={props?.onClose}
                  />
                </div>
              </div>
              <div className="loginScreen_Box4">
                <Form
                  onFinish={verification ? verifyOtp : onFinish}
                  initialValues={{ code: "+91" }}
                >
                  {verification ? (
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Input 6 digit verification code !",
                        },
                      ]}
                      className="d-flex justify-items-start mb-2"
                    >
                      <InputOTP
                        autoFocus
                        inputType="numeric"
                        inputStyle={{ maxWidth: 56 }}
                      />
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        name={"name"}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={t("loginScreen.title.input_name")}
                          className="input_radius"
                        />
                      </Form.Item>
                      <Form.Item
                        name={"phonenumber"}
                        rules={[
                          {
                            required: true,
                            // message: "",
                          },
                        ]}
                        style={{
                          background: "white",
                          borderRadius: 30,
                          paddingTop: 5,
                          height: 63,
                        }}
                      >
                        <Input
                          addonBefore={Selector}
                          placeholder={t("loginScreen.title.input_phone")}
                          type="number"
                          bordered={false}
                          size="large"
                          style={{
                            paddingTop: 5,
                          }}
                        />
                      </Form.Item>
                      <div id="recaptcha"></div>
                    </>
                  )}
                  {error ? (
                    <>
                      <div className="error_Txt">{error}</div>
                      <br />
                    </>
                  ) : null}
                  <Col md={6}>
                    <Button
                      className="primary_bttn1"
                      block
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {verification
                        ? t("loginScreen.title.submit")
                        : t("loginScreen.title.enter")}
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
}

export default Existing;
