import { Card, DatePicker, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/header/header";
import { API } from "../../../config/API";
import { GET } from "../../../utils/apiRequest";
import { Col, Container, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import AdminPageHeader from "../../../component/adminPageHeader";
import OrdersTable from "./table";
import LoadingBox from "../../../component/loadingBox";

function ProductOrders() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      let url = API.GET_ALL_ORDERS;
      const data: any = await GET(url, null);
      setProduct(data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="dataTable-Box1">
      <Header unfixed={true} />
      <Container>
        <div className="dashboardHeader-Box2">
          <div className="dashboardHeader-Box3">
            <Card>
              <AdminPageHeader title={"Orders"} button={""} navigate={""} />
              <Row>
                <Col md={3}></Col>
                <Col md={5}>
                  <DatePicker.RangePicker style={{ width: "100%" }} />
                </Col>
                <Col md={4}>
                  <Input suffix={<IoSearchOutline />} placeholder="Search " />
                </Col>
              </Row>
              <br />
              <br />
              {loading ? (
                <LoadingBox />
              ) : (
                <OrdersTable data={product} api={loadData} />
              )}
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProductOrders;
