import { Button, Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
const Measurement = (props: any) => {
  const onFinish = async (val: any) => {
    try {
      const obj = {
        length: val?.length,
        shoulder: val?.shoulder,
        chest: val?.chest,
        upper_hip: val?.upper_hip,
        embroidary: val?.embroidary,
        hip: val?.hip,
        sleeve_Length: val?.sleeve_Length,
        sleeve_Opening: val?.sleeve_Opening,
        neck: val?.neck,
      };
      props?.measurement(obj);
      props?.onSubmit();
    } catch (err) {
      props?.loading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <Form onFinish={onFinish}>
        <Row>
          <Col md={6}>
            <label className="formLabel">Length</label>
            <Form.Item name="length">
              <Input />
            </Form.Item>
            <label className="formLabel">Shoulder</label>
            <Form.Item name="shoulder">
              <Input />
            </Form.Item>
            <label className="formLabel">Chest</label>
            <Form.Item name="chest">
              <Input />
            </Form.Item>
            <label className="formLabel">Upper Hip</label>
            <Form.Item name="upper_hip">
              <Input />
            </Form.Item>
            <label className="formLabel">embroidary</label>
            <Form.Item name="embroidary">
              <Input />
            </Form.Item>
          </Col>

          <Col md={6}>
            <label className="formLabel">Hip</label>
            <Form.Item name="hip">
              <Input />
            </Form.Item>
            <label className="formLabel">Sleev Length</label>
            <Form.Item name="sleeve_Length">
              <Input />
            </Form.Item>
            <label className="formLabel">Sleev Opening</label>
            <Form.Item name="sleeve_Opening">
              <Input />
            </Form.Item>
            <label className="formLabel">Neck</label>
            <Form.Item name="neck">
              <Input />
            </Form.Item>
          </Col>
          <Col md={{ span: 4, offset: 8 }}>
            <Button
              htmlType="submit"
              block
              size="large"
              className="primary_bttn3"
              loading={props?.loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Measurement;
