import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import Header from "../../component/header/header";
import Order from "./component/orders";
import Reminders from "./component/reminders";
import TotalSalesAndProfits from "./component/totalSaleProfilt";

function Dashboard() {
  return (
    <div className="dashBoard-Box1">
      <Header unfixed={true} />
      <br />
      <div className="overFlow-Box1">
        <div className="overFlow-Box2">
          <TotalSalesAndProfits />
          <br />
          <br />
          <Row>
            <Col md={6}>
              <Order />
            </Col>
            <Col md={6}>
              <Reminders />
            </Col>
          </Row>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
