import { Button, Form, Input, Select, message, notification } from "antd";
import { InputOTP } from "antd-input-otp";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosContact } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImagePicker from "../../../component/imagePIcker";
import { API } from "../../../config/API";
import CountryCode from "../../../config/countryCode.json";
import { Auth } from "../../../config/fireBase";
import { login } from "../../../redux/slice/userSlice";
import { POST } from "../../../utils/apiRequest";

function NewCustomer(props: any) {
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [verification, setverification] = useState(false);
  const [error, setError] = useState<any>(null);
  const [toggle, toggleModal] = useState(false);
  const [image, setImage] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleImageUpdated = (img: any) => {
    console.log(img);
    setImage(img);
  };

  const onFinish = async (val: any) => {
    console.log(val);
    setIsLoading(true);

    try {
      setData(val);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {
        size: "invisible",
      });
      let phone = `${val.code}${val.phonenumber}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
        setIsLoading(false);
      } else {
        setError("Verification Failed");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const verifyOtp = async (val: any) => {
    try {
      setIsLoading(true);
      let otp = val.otp.join("");
      let verify = await autho.confirm(otp);
      if (verify?.user?.phoneNumber) {
        signUp();
      } else {
        message.error("Incorrect OTP");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const signUp = async () => {
    let body = {
      name: data?.name,
      email: data?.email,
      countrycode: data?.code,
      phone_no: data?.phonenumber,
      image: image,
      address: data?.address,
      mobileverified: 1,
      role: "user",
    };
    try {
      setIsLoading(true);
      let url = API.REGISTER;
      const data: any = await POST(url, body);

      if (data?.status === true) {
        notification.success({
          message: data?.message,
        });
        onLogin();
      } else {
        setError(data?.message);
        setIsLoading(false);
        // setTimeout(() => {
        //   props?.onClose();
        // }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const onLogin = async () => {
    setIsLoading(true);
    try {
      let url = API.LOGIN;
      let body = {
        name: data?.name,
        phone_no: data?.phonenumber,
      };
      const resp: any = await POST(url, body);

      if (resp.status === true) {
        dispatch(login(resp?.data));
        notification.success({
          message: resp?.message,
        });
        navigate(
          resp?.data?.mobileverified === "2" ? "/Auth" : `/usr/${user?.id}`
        );
        setIsLoading(false);
      } else {
        setError(resp?.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 85 }} showSearch={true}>
        {CountryCode.map((item: any) => (
          <Select.Option key={item} value={item.dial_code}>
            {item.dial_code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  const rules = [
    {
      required: true,
      message: "",
    },
  ];
  return (
    <>
      {props?.open && (
        <Col md={12}>
          <div className="LoginModal_Box1">
            <div className="LoginModal_Box2">
              <div className="loginScreen_Box3">
                <div className="loginModal_Txt1">
                  {t("loginScreen.title.new_customer")}
                </div>
                {props.switchValue ? (
                  ""
                ) : (
                  <div>
                    <RxCross2
                      cursor={"pointer"}
                      color="#fff"
                      size={34}
                      onClick={props?.onClose}
                    />
                  </div>
                )}
              </div>
              <div className="loginScreen_Box4">
                <Form
                  onFinish={verification ? verifyOtp : onFinish}
                  initialValues={{ code: "+91" }}
                >
                  {verification ? (
                    <>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Input 6 digit verification code !",
                          },
                        ]}
                        className="d-flex justify-items-start mb-2"
                      >
                        <InputOTP
                          autoFocus
                          inputType="numeric"
                          inputStyle={{ maxWidth: 56 }}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name={"name"}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={t("loginScreen.title.input_name")}
                          className="input_radius"
                          suffix={
                            <div
                              style={{
                                width: 60,
                                height: 60,
                                border: "1px solid #0000001A",
                                borderRadius: "50%",
                                position: "relative",
                                left: 12,
                                display: "grid",
                                placeItems: "center",
                              }}
                            >
                              <div>
                                {image ? (
                                  <img
                                    className=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                    src={image}
                                    alt=""
                                  />
                                ) : (
                                  <IoIosContact
                                    size={60}
                                    color="#0000001A"
                                    cursor={"pointer"}
                                    onClick={() => toggleModal(true)}
                                  />
                                )}
                              </div>
                            </div>
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name={"phonenumber"}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                        style={{
                          background: "white",

                          borderRadius: 30,
                          paddingTop: 5,
                          height: 63,
                        }}
                      >
                        <Input
                          addonBefore={Selector}
                          placeholder={t("loginScreen.title.input_phone")}
                          type="number"
                          bordered={false}
                          size="large"
                          style={{
                            paddingTop: 5,
                          }}
                        />
                      </Form.Item>
                      <div id="recaptcha"></div>

                      <Form.Item
                        name={"email"}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={t("loginScreen.title.input_mail")}
                          className="input_radius"
                        />
                      </Form.Item>
                      <Form.Item
                        name={"address"}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={t("loginScreen.title.input_address")}
                          className="input_radius"
                        />
                      </Form.Item>
                    </>
                  )}
                  {error ? (
                    <>
                      <div className="error_Txt">{error}</div>
                      <br />
                    </>
                  ) : null}
                  <Col md={6} xs={12}>
                    <Button
                      className="primary_bttn1"
                      block
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {verification
                        ? t("loginScreen.title.submit")
                        : t("loginScreen.title.register")}
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      )}
      <ImagePicker
        open={toggle}
        modalClose={() => toggleModal(false)}
        onImageUpdated={(data: any) => handleImageUpdated(data)}
        type="registration"
      />
    </>
  );
}

export default NewCustomer;
