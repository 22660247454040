import { Card, Form, Select, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../component/header/header";
import AdminPageHeader from "../../../component/adminPageHeader";
import { useParams } from "react-router";
import { API } from "../../../config/API";
import { GET, PUT } from "../../../utils/apiRequest";
import { useEffect, useState } from "react";

function OrderTableViewPage(props: any) {
  const [data, setData] = useState<any>();
  const [neckImages, setNeckImages] = useState<any>([]);

  useEffect(() => {
    getOrderDetails();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     let imgData = data?.neck_type;
  //     if (imgData?.startsWith("[") && imgData?.endsWith("]")) {
  //       setNeckImages(JSON.parse(imgData));
  //     } else {
  //       setNeckImages([imgData]);
  //     }
  //   }
  // }, [data]);

  const id = useParams();

  const getOrderDetails = async () => {
    try {
      let url = API.GET_ALL_ORDERS + "/" + id?.id;
      const reponse: any = await GET(url, "");
      setData(reponse);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = async (value: any) => {
    await UpdateStatus(value);
    getOrderDetails();
  };

  const UpdateStatus = async (value: any) => {
    try {
      let obj = {
        status: value,
      };
      let url = API.UPDATE_ORDER_STATUS + "/" + id?.id;
      const response = await PUT(url, obj);
      if (response) {
        message.success("Successfully Updated The Status");
      } else {
        message.error("Something Went Wrong !!");
      }
    } catch (error) {
      console.error("Error while updating status:", error);
    }
  };

  return (
    <>
      <div className="dashBoard-Box1">
        <Header unfixed={true} />
        <div className="dashboardHeader-Box2">
          <div className="dashboardHeader-Box3">
            <Card bordered={false}>
              <AdminPageHeader
                title={"Order Details"}
                navigate={"/Auth/orders"}
              />
              <br />
              <br />
              <Form>
                <Container>
                  <Row>
                    <Col md={6}>
                      <div>
                        <div className="screen-Txt2">PRODUCT</div>
                        <br />
                        <img
                          className="MeasurmentScreen-Img"
                          style={{ objectFit: "contain", height: "350px" }}
                          src={data?.productImage}
                          alt=""
                        />
                        <br />
                        <br />
                        <div className="screen-Txt2">
                          <b>Product Name:</b>
                          {data?.product_Ordered?.product_name}
                          <br />
                          <b>Price:</b> {data?.product_Ordered?.price}
                          <br />
                          <b>Information:</b>
                          {data?.product_Ordered?.information}
                          <br />
                          <b>Description:</b>
                          {data?.product_Ordered?.description}
                          <br />
                        </div>
                        <br />
                        <div style={{ display: "flex" }}>
                          <div className="profile-title">
                            {data?.status == 1
                              ? "PENDING"
                              : data?.status == 2
                              ? "DELIVERED"
                              : data?.status == 3
                              ? "PROGRESS"
                              : ""}
                          </div>
                          &nbsp;
                          <div>
                            <Select
                              placeholder={"Change The Status"}
                              onChange={(value) => onChange(value)}
                            >
                              <Select.Option value="1">Pending</Select.Option>
                              <Select.Option value="2">Delivered</Select.Option>
                              <Select.Option value="3">Progress</Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <Col md={6}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <div className="screen-Txt2">PRODUCT TYPE</div>
                            <br />
                            <img
                              className="MeasurmentScreen-Img"
                              style={{ objectFit: "contain", height: "150px" }}
                              src={data?.productType}
                              alt=""
                            />
                            <br />
                            <br />
                            <div className="screen-Txt2">
                              <b>Product Type Name: </b>
                              {data?.productTypeName}
                            </div>
                          </div>

                          <div className="screen-Txt2">
                            <div>MEASUREMENT</div>
                            <br />
                            <div style={{ display: "flex" }}>
                              <div>
                                <b>neck:</b> {data?.neck}
                                <br />
                                <b>length:</b> {data?.length}
                                <br />
                                <b>chest:</b> {data?.neck}
                                <br />
                                <b>hip:</b> {data?.upper_hip}
                              </div>
                              &nbsp; &nbsp; &nbsp;
                              <div>
                                <b>sleeve_Length:</b>
                                {data?.sleeve_Length}
                                <br />
                                <b>upper_hip:</b> {data?.upper_hip}
                                <br />
                                <b>meterial:</b> {data?.meterial}
                                <br />
                                <b>shoulder:</b> {data?.shoulder}
                                <br />
                                <b>sleeve_Opening:</b> {data?.sleeve_Opening}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <br />
                      <Col md={6}>
                        <div>
                          <div className="screen-Txt2">TYPES</div>
                          <br />
                          {data?.neck_type &&
                            data?.neck_type?.length > 0 &&
                            data?.neck_type?.map((img: any, index: any) => (
                              <img
                                key={index}
                                className="MeasurmentScreen-Img"
                                style={{
                                  objectFit: "contain",
                                  height: "100px",
                                }}
                                src={img}
                                alt={`Neck Type ${index}`}
                              />
                            ))}
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderTableViewPage;
