import { createSlice } from "@reduxjs/toolkit";
const productTypeSlice = createSlice({
  name: "productType",
  initialState: {
    productType: [],
  },
  reducers: {
    storeProductType: (state, action) => {
      console.log(action.payload, "action");

      state.productType = action.payload;
    },
    clearProductType: (state) => {
      state.productType = [];
    },
  },
});

export const { storeProductType, clearProductType } = productTypeSlice.actions;
export default productTypeSlice.reducer;
