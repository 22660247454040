// import React, { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeader";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import PaymentMethod from "../../component/payment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";

function BillingScreen() {
  const { productId } = useParams();
  const { accessoriesItem, products } = useSelector((state: any) => {
    return state;
  });

  const assessoryPrice = accessoriesItem?.options.map((item: any) => ({
    type: item.name,
    size: item.price,
  }));

  let totalSize = 0;

  assessoryPrice.forEach((item: any) => {
    totalSize += parseInt(item.size);
  });
  const totalBillAmount = Number(products?.products?.price) + Number(totalSize);

  return (
    <>
      <PageHeader
        title1={"PODUCT"}
        path1={`/usr/product/${productId}`}
        title2={"TYPE"}
        path2={`/usr/product/${productId}/type/${productId}`}
        title3={"MEASUREMENT"}
        path3={`/usr/product/${productId}/type/${productId}/measurment`}
        title4={"SUMMARY"}
        path4={`/usr/product/${productId}/type/${productId}/measurment/summary`}
        title5={"BILLING"}
      />
      <br />
      <br />
      <br />
      <Container>
        <div className="screen-Txt2">BILLING</div>
        <br />
        <br />
        <Row>
          <Col md={8}>
            <Row align={"middle"} justify={"space-between"}>
              <div className="screen-Txt2">Product</div>
              <table className="table">
                <tbody>
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      {products?.products?.product_name}
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      {products?.products?.price}
                    </td>
                  </tr>
                  <div className="screen-Txt4" style={{ textAlign: "end" }}>
                    <b>Total: {products?.products?.price}</b>
                  </div>
                  <hr />
                  <div className="screen-Txt2">Assessory Type</div>
                  {assessoryPrice?.map((item: any, index: number) => (
                    <tr
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <td
                        className="screen-Txt4"
                        style={{ borderBottom: "none" }}
                      >
                        {item?.type}
                      </td>
                      <td
                        className="screen-Txt4"
                        style={{ borderBottom: "none" }}
                      >
                        {item?.size}
                      </td>
                    </tr>
                  ))}
                  <div className="screen-Txt4" style={{ textAlign: "end" }}>
                    <b>Total: {totalSize}</b>
                  </div>
                  <hr />
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>TOTAL BILL TO BE PAID</b>
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>
                        {Number(products?.products?.price) + Number(totalSize)}
                        &nbsp; SAR
                      </b>
                    </td>
                  </tr>
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>PAYMENT METHOD FEE</b>
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>xxx</b>
                    </td>
                  </tr>
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>SHIPPING CHARGES</b>
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>xxx</b>
                    </td>
                  </tr>
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>DISCOUNT</b>
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>xxx</b>
                    </td>
                  </tr>
                  <hr />
                  <tr
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b> TOTAL</b>
                    </td>
                    <td
                      className="screen-Txt4"
                      style={{ borderBottom: "none" }}
                    >
                      <b>
                        {Number(products?.products?.price) + Number(totalSize)}
                        &nbsp; SAR
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
            <br />
            <br />
          </Col>
          <Col md={{ span: 8, offset: 8 }}>
            <PaymentMethod totalBillAmount={totalBillAmount} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BillingScreen;
