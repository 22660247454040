import { Tag } from "antd";
import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import { IoBagHandle } from "react-icons/io5";
import { IoCloseCircleSharp } from "react-icons/io5";

function OrderList() {
  const order = [
    {
      id: 1,
      orderId: 543223486,
      product: "shirt",
      date: "01/01/2024",
      total: 1213,
      paid: 1000,
      deliver: true,
    },
    {
      id: 2,
      orderId: 2432234576,
      product: "thawab",
      date: "22/11/2024",
      total: 4023,
      paid: 0,
      deliver: false,
    },
    {
      id: 3,
      orderId: 543223486,
      product: "shirt",
      date: "01/01/2024",
      total: 1213,
      paid: 1000,
      deliver: true,
    },
    {
      id: 4,
      orderId: 2432234576,
      product: "thawab",
      date: "22/11/2024",
      total: 4023,
      paid: 0,
      deliver: false,
    },
  ];
  return (
    <>
      {order?.map((item: any) => {
        return (
          <>
            <div className="orderList-main">
              <Table responsive style={{ tableLayout: "fixed" }}>
                <tr>
                  <td>
                    <div className="orderList-Box1">
                      <IoBagHandle size={24} color="#002da2" />
                    </div>
                  </td>
                  <td>
                    <div className="orderList-td">
                      <div className="orderList-Txt2">ORDER ID</div>
                      <div className="orderList-Txt3">{item?.orderId}</div>
                    </div>
                  </td>
                  <td>
                    <div className="orderList-td">
                      <div className="orderList-Txt2"> SHIRT </div>
                      <div className="orderList-Txt3">{item?.date}</div>
                    </div>
                  </td>
                  <td>
                    <div className="orderList-td">
                      <div className="orderList-Txt3">
                        TOTAL :
                        <span className="orderList-Txt2 orderList-black ">
                          &nbsp;{item?.total}
                          <span className="orderList-Txt4"> SAR</span>
                        </span>
                      </div>
                      <div className="orderList-Txt3">
                        PAID :
                        <span className="orderList-Txt2 orderList-black ">
                          &nbsp;{item?.paid}
                          <span className="orderList-Txt4"> SAR</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="orderList-td right">
                      <Tag color={item?.deliver === true ? "green" : "red "}>
                        {item?.deliver === true ? "DELIVERED" : "CANCELED"}
                      </Tag>
                    </div>
                  </td>
                </tr>
              </Table>
            </div>
            <br />
          </>
        );
      })}
    </>
  );
}

export default OrderList;
