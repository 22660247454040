import React from "react";
import Menu from "./menu.json";
import { useNavigate } from "react-router-dom";
function SideBar() {
  const navigate = useNavigate();
  return (
    <>
      <div className="sideBar-Box1">
        <div className="sideBar-Txt1">MENU</div>
        <div>
          {Menu?.map((item: any) => {
            return (
              <>
                <div
                  className="sideBar-Txt2"
                  onClick={() => navigate(item?.navigate)}
                >
                  {item?.title}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SideBar;
