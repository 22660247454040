import { Button } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { login, logout } from "../../redux/slice/userSlice";
import "./styles.scss";

function Header(props: any) {
  const location = useLocation();
  const { id } = useParams();
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(login(""));
    dispatch(logout());
  };
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div
        className={
          // location?.pathname === `/usr/${id}`
          props.fixed === true
            ? "header-Box1-transparent"
            : props?.unfixed
            ? "header-DashBoard"
            : "header-Box1"
        }
      >
        <div
          className={
            props.background ? "header-Box2-transparent" : "header-Box2"
          }
        >
          <Container>
            {/* <br /> */}
            <Row className="align-items-center">
              <Col md={6}>
                <div className="header-Box3">
                  {location?.pathname === "/Auth" ? (
                    <div className="header-txt1">
                      {t("loginScreen.title.welcome")}
                    </div>
                  ) : (
                    <>
                      <div
                        className="header-txt1"
                        onClick={() => changeLanguage("ar")}
                      >
                        العربية
                      </div>
                      <div className="header-Box4"></div>
                      <div
                        className="header-txt1"
                        onClick={() => changeLanguage("en")}
                      >
                        ENGLISH
                      </div>
                    </>
                  )}
                </div>
              </Col>
              <Col md={6}>
                {props?.hide ? null : (
                  <Row>
                    <Col md={6}>
                      <Button
                        className="header_Bttn1"
                        block
                        // onClick={() => props?.switchValue(false)}
                      >
                        SIGNED IN HADAQ RYD
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        className="header_Bttn2"
                        block
                        onClick={() => props?.switchValue(true)}
                      >
                        ADMIN
                      </Button>
                    </Col>
                  </Row>
                )}
                {props?.show && (
                  <Row className="align-items-center">
                    <Col md={5}></Col>
                    <Col md={6} style={{ lineHeight: "36px" }}>
                      <div className="header-txt2">
                        {t("loginScreen.title.company_name_arb")}
                      </div>
                      <div className="header-txt3">
                        {t("loginScreen.title.company_name")}
                      </div>
                    </Col>
                    <Col md={1}>
                      <RiLogoutCircleLine
                        size={30}
                        color="white"
                        cursor={"pointer"}
                        onClick={onLogout}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {/* <br /> */}
          </Container>
        </div>
      </div>
    </>
  );
}

export default Header;
