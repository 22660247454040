import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../screens/dashboard";
import UserScreen from "../screens/adminScreen/userScreen";
import SideBar from "./sideBar";
import "./styles.scss";
import ProductScreen from "../screens/adminScreen/productScreen";
import AddProduct from "../screens/adminScreen/productScreen/component/addProduct";
import ProductOrders from "../screens/adminScreen/orders";
import OrderTableViewPage from "../screens/adminScreen/orders/viewPage";
function Navigation() {
  const { Sider, Content } = Layout;

  return (
    <>
      <Layout
        className="Navigation-container"
        style={{ background: "#E3E3E3" }}
      >
        <Sider className="navigation-Sider">
          <SideBar />
        </Sider>
        <Layout>
          <Content style={{ background: "#E3E3E3" }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/user" element={<UserScreen />} />
              <Route path="/product" element={<ProductScreen />} />
              <Route path="/add-products" element={<AddProduct />} />
              <Route path="/orders" element={<ProductOrders />} />
              <Route
                path="/orderTable/viewPage/:id"
                element={<OrderTableViewPage />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default Navigation;
