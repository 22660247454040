import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Image2 from "../../assets/images/homeImage.jpg";
import Image1 from "../../assets/images/homeImage2.jpg";
import Header from "../../component/header/header";
import Existing from "./component/existingCustomer";
import NewCustomer from "./component/newCustomer";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function LoginScreen() {
  const { t } = useTranslation();
  const [newLogin, setNewLogin] = useState(false);
  const [existing, setExisting] = useState(false);
  return (
    <>
      <br />
      <Header fixed={true} background={true} hide={true} />
      <div
        className="block1_Box1"
        style={{
          backgroundImage: `url(${newLogin || existing ? Image1 : Image2})`,
        }}
      >
        <Row className="g-0">
          <Col md={6}>
            <div className="block1_Box2">
              <div
                className={
                  newLogin || existing ? "head_Secondary_Txt1" : "head_Txt1"
                }
              >
                {t("loginScreen.title.company_name_arb")}
              </div>
              <div
                className={
                  newLogin || existing ? "head_Secondary_Txt2" : "head_Txt2"
                }
              >
                {t("loginScreen.title.company_name")}
              </div>
            </div>
          </Col>

          {newLogin || existing ? (
            <>
              {newLogin ? (
                <Col md={5}>
                  <div>
                    <NewCustomer
                      open={true}
                      onClose={() => setNewLogin(false)}
                    />
                  </div>
                </Col>
              ) : (
                <Col md={5}>
                  <div>
                    <Existing open={true} onClose={() => setExisting(false)} />
                  </div>
                </Col>
              )}
            </>
          ) : (
            <Col md={6}>
              <div className="block1_Box3">
                <Col md={6}>
                  <Button
                    className="loginScreen_bttn1"
                    onClick={() => setNewLogin(true)}
                  >
                    {t("loginScreen.title.login_new")}
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    className="loginScreen_bttn2"
                    onClick={() => setExisting(true)}
                  >
                    {t("loginScreen.title.existing")}
                  </Button>
                </Col>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default LoginScreen;
