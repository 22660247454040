import PageHeader from "../../component/pageHeader";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceIcon from "../../assets/images/invoiceVector.png";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
function InvoiceScreen() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { User } = useSelector((state: any) => {
    return state;
  });

  return (
    <>
      <PageHeader
        title1={"PODUCT"}
        path1={`/usr/product/${productId}`}
        title2={"TYPE"}
        path2={`/usr/product/${productId}/type/${productId}`}
        title3={"MEASUREMENT"}
        path3={`/usr/product/${productId}/type/${productId}/measurment`}
        title4={"ACCESSORIES"}
        path4={`/usr/product/${productId}/type/${productId}/measurment/summary/accessories`}
        title5={"SUMMARY"}
        path5={`/usr/product/${productId}/type/${productId}/measurment/summary`}
        title6={"BILLING"}
        path6={`/usr/product/${productId}/type/${productId}/measurment/summary/accessories/billing`}
        title7={"INVOICE"}
      />
      <br />
      <br />
      <br />
      <Container>
        <Col md={12}>
          <img src={InvoiceIcon} alt="" />
        </Col>
        <br />
        <br />
        <br />
        <Col md={12} className="invioce-Txt1">
          YOUR ORDER HAS BEEN PLACED.
        </Col>
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md={8} className="screen-Txt2">
            ORDER NUMBER 2372023
          </Col>
          <Col md={2}>
            <Button
              block
              className="primary_bttn3"
              size="large"
              onClick={() => navigate(`/usr/${User.user.id}`)}
            >
              ORDERS
            </Button>
          </Col>
          <Col md={2}>
            <Button block className="primary_bttn3" size="large">
              INVOICE
            </Button>
          </Col>
          <Col md={2}>
            <Button block className="primary_bttn4" size="large">
              SHARE
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default InvoiceScreen;
