import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/header/header";
import PageHeader from "../../component/pageHeader";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";
import { useDispatch } from "react-redux";
import { storeProductType } from "../../redux/slice/productTypeSlice";
import { useSelector } from "react-redux";
import LoadingBox from "../../component/loadingBox";

function ProductTypeScreen() {
  const { id, productId, type } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const { productType }: any = useSelector((state: any) => {
    return state;
  });
  let selectedImage = productType?.productType?.val;

  const handleSelect = (val: any, image: any, assessoryName: any) => {
    let assessory = {
      image,
      assessoryName,
      val,
    };
    setSelected(val);
    dispatch(storeProductType(assessory));
    navigate(
      `/usr/product/${productId}/type/${type}/measurment`
      // `usr/profile/${id}/product/${productId}/producttype/${type}/measurment`
    );
  };

  const getCategoryList = async () => {
    setIsloading(true);
    try {
      let url = API.GET_ALL_CATEGORY_LIST + `/` + productId;
      const response: any = await GET(url, "");
      setData(response?.data);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      <Header hide={true} show={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PageHeader
        title1={"PODUCT"}
        path1={`/usr/product/${id}`}
        title2={"TYPE"}
      />
      <div className="white-Card" style={{ borderRadius: 0 }}>
        <Container>
          <br />
          <br />
          {isloading ? (
            <LoadingBox />
          ) : (
            <Row>
              {data?.map((item: any, index: any) => {
                return (
                  <>
                    <Col
                      key={index}
                      md={4}
                      onClick={() =>
                        handleSelect(item?.id, item?.image, item?.category_name)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className={
                            selected
                              ? "ProductTypeScreenSelected-Box1"
                              : "ProductTypeScreen-Box1"
                          }
                        >
                          <img
                            className="Product-Img"
                            src={item?.image}
                            style={{
                              border:
                                selectedImage === item?.id
                                  ? "5px solid #dedcdc"
                                  : "2px solid #dedcdc",
                              borderRadius: "75px",
                            }}
                            alt={""}
                          />
                        </div>
                        <br />
                        <div
                          className="screen-Txt3"
                          style={{ textAlign: "center" }}
                        >
                          {item?.category_name}
                          <br />
                          {selectedImage === item?.id ? "Selected Image" : ""}
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            </Row>
          )}

          <br />
          <br />
          {/* <div className="screen-Txt2">
            THAWB TYPE :
            {selected && (
              <span className="screen-Txt2 screen-Txt3 ps-4">
                {type}
                <span className="ps-3">
                  <RxCross2 color="black" size={20} />
                </span>
              </span>
            )}
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default ProductTypeScreen;
