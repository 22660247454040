export const API = {
  //  BASE_URL: "http://localhost:8015/",
  BASE_URL: "https://server.bairuhatech.com/hadaq/",

  LOGIN: "users/login",
  REGISTER: "users/register",
  USERS: "users",
  UPDATE_PROFILE: "users/",
  S3_IMAGE_UPLOAD: "img_upload/upload",
  PRODUCT: "Products/product",
  GET_PRODUCT_BY_ID: "Products/",
  ADD_PRODUCT: "Products/addProduct",
  CATEGORY: "productCategory/category/",
  GET_ACCESSORIES_LIST: "accessories",
  GET_BILLING_DATA: "orderDetails/billing/",
  UPDATE_ORDER_STATUS: "orderDetails/status",
  GET_ALL_CATEGORY_LIST: "productCategory/category",
  ADD_PRODUCT_ORDER: "orderDetails/add",
  GET_ALL_ORDERS: "orderDetails",
  GET_ALL_ORDERS_BY_USERID: "orderDetails/getByUserId/",
  GET_ALL_MEASUREMENT_BY_PRODUCTID: "Measurement/",
};
