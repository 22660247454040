import { createSlice } from "@reduxjs/toolkit";
const summarySlice = createSlice({
  name: "summary",
  initialState: {
    options: [],
  },
  reducers: {
    storeSummary: (state, action) => {
      state.options = action.payload;
    },
    clearSummary: (state) => {
      state.options = [];
    },
  },
});

export const { storeSummary, clearSummary } = summarySlice.actions;
export default summarySlice.reducer;
