import { Button } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../component/pageHeader";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";
import { useEffect, useState } from "react";

function Accessories() {
  const [product, setProduct] = useState<any>({});
  const { accessoriesItem, measurements, productType, products } = useSelector(
    (state: any) => {
      return state;
    }
  );

  const loadData = async () => {
    try {
      let url = API.GET_PRODUCT_BY_ID + productId;
      const data: any = await GET(url, null);
      if (data != null) {
        setProduct(data);
      }
    } catch (err) {}
  };
  useEffect(() => {
    loadData();
  }, []);

  const { id, productId, type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  let data: any = [];
  data?.push(location?.state?.data);
  return (
    <>
      <PageHeader
        title1={"PODUCT"}
        path1={`/usr/product/${productId}`}
        title2={"TYPE"}
        path2={`/usr/product/${productId}/type/${productId}`}
        title3={"MEASUREMENT"}
        path3={`/usr/product/${productId}/type/${productId}/measurment`}
        title4={"SUMMARY"}
        path4={`/usr/product/${productId}/type/${productId}/measurment/summary`}
        title5={"ACCESSORIES"}
      />
      <br />
      <br />
      <br />
      <Container>
        <div className="screen-Txt2">PRODUCTS</div>
        <br />
        <Row>
          <Col md={6}>
            <div>
              <label className="screen-Txt2">Product Image</label>
              <br />
              <br />
              <img
                className="MeasurmentScreen-Img"
                style={{ height: "300px" }}
                src={products?.products?.image}
                alt=""
              />
              <br />
              <label className="screen-Txt4">
                <b>Name: </b>
                {products?.products?.product_name}
                <br />
                <b>Price: </b>
                {products?.products?.price}
              </label>
            </div>
          </Col>
          <Col md={6}>
            <>
              <Row>
                <Col md={4}>
                  <div>
                    <label className="screen-Txt2">Product Type</label>
                    <br />
                    <br />
                    <img
                      className="MeasurmentScreen-Img"
                      style={{ height: "100px" }}
                      src={productType?.productType?.image}
                      alt=""
                    />
                    <br />
                    <label className="screen-Txt4">
                      <b>Name: </b>
                      {productType?.productType?.assessoryName}
                    </label>
                  </div>
                </Col>
                <Col md={8}>
                  <label className="screen-Txt2">Measurement</label>
                  <div style={{ display: "flex" }}>
                    <br />
                    <div>
                      <label className="screen-Txt4">
                        <b>length: </b>
                        {measurements?.measurement?.length}
                        <br />
                        <b>chest: </b>
                        {measurements?.measurement?.chest}
                        <br />

                        <b>hip: </b>
                        {measurements?.measurement?.hip}
                        <br />
                        <b>neck: </b>
                        {measurements?.measurement?.neck}
                        <br />
                        <b>shoulder: </b>
                        {measurements?.measurement?.shoulder}
                        <br />
                        <b>upper_hip: </b>
                        {measurements?.measurement?.upper_hip}
                      </label>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <label className="screen-Txt4">
                        <b>sleeve_Length: </b>
                        {measurements?.measurement?.sleeve_Length}
                        <br />
                        <b>sleeve_Opening: </b>
                        {measurements?.measurement?.sleeve_Opening}
                        <br />

                        <b>sleeve_Opening: </b>
                        {measurements?.measurement?.sleeve_Opening}
                        <br />
                        <b>sleeve_Opening: </b>
                        {measurements?.measurement?.sleeve_Opening}
                        <br />
                        <b>embroidary: </b>
                        {measurements?.measurement?.embroidary}
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>

              <br />
              <label className="screen-Txt2">Accessories</label>
              <Row>
                {accessoriesItem?.options?.map((item: any, index: number) => {
                  return (
                    <Col md={2}>
                      <div key={index}>
                        <br />
                        <img
                          className="MeasurmentScreen-Img"
                          style={{ height: "100px" }}
                          src={item?.image}
                          alt=""
                        />
                        <br />
                        <label className="screen-Txt4">
                          <b>Type: </b>
                          {item?.type}
                          <br />
                          <b>Name: </b>
                          {item?.name}
                          <br />
                          <b>Price:</b> {item?.price}
                        </label>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={6}>
            <Col md={{ span: 4 }}>
              <Button
                block
                size="large"
                className="primary_bttn3"
                onClick={() => {
                  console.log("=======id======", id);
                  navigate(
                    `/usr/product/${productId}/type/${type}/measurment`
                    // `usr/profile/${id}/product/${productId}/producttype/${type}/measurment`
                  );
                }}
              >
                ADD TO LIST
              </Button>
            </Col>
          </Col>
          <Col md={6}>
            <Col md={{ span: 4, offset: 8 }}>
              <Button
                block
                size="large"
                className="primary_bttn4"
                onClick={() =>
                  navigate(
                    `/usr/product/${productId}/type/${type}/measurment/summary/accessories/billing`
                  )
                }
              >
                PROCESS ORDER
              </Button>
            </Col>
          </Col>
          <br />
          <br />
        </Row>
      </Container>
    </>
  );
}

export default Accessories;
