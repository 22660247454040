import { Button, Card, Input, Pagination, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import { TbReload } from "react-icons/tb";

function ProductDataTable(props: any) {
  console.log(props);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: <p className="table-Title">#</p>,
      dataIndex: "id",
      key: "id",

      render: (record: any) => {
        console.log(record);
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: <p className="table-Title">Image</p>,
      dataIndex: "image",
      key: "image",
      render: (record: any) => {
        return (
          <div className="table-Txt">
            <img
              src={record}
              alt="Product Image"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        );
      },
    },
    {
      title: <p className="table-Title">Product Name</p>,
      dataIndex: "product_name",
      key: "product_name",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Price</p>,
      dataIndex: "price",
      key: "price",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Information</p>,
      dataIndex: "information",
      key: "information",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Description</p>,
      dataIndex: "description",
      key: "description",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    // {
    //   title: <p className="table-Title">Material</p>,
    //   dataIndex: "meterial",
    //   key: "meterial",

    //   render: (record: any) => {
    //     return <div className="table-Txt">{record}</div>;
    //   },
    // },
    // {
    //   title: <p className="table-Title">Barcode</p>,
    //   dataIndex: "barcode",
    //   key: "barcode",

    //   render: (record: any) => {
    //     return <div className="table-Txt">{record}</div>;
    //   },
    // },
    // {
    //   title: <p className="table-Title">Brand</p>,
    //   dataIndex: "brand",
    //   key: "brand",

    //   render: (record: any) => {
    //     return <div className="table-Txt">{record}</div>;
    //   },
    // },

    {
      title: <p className="table-Title">actions</p>,

      key: "action",
      render: (_, record) => {
        return (
          <div className="table-Action">
            <div className="">
              <Space size="middle">
                <FaEye
                  size={20}
                  color="#2c698d"
                  cursor="pointer"
                  // onClick={() => props.onEdit(record, "view")}
                />
              </Space>
            </div>
            <div className="">
              <Space size="middle">
                <RiPencilFill
                  size={20}
                  color="#2c698d"
                  cursor="pointer"
                  // onClick={() => props.onEdit(record, "edit")}
                />
              </Space>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="">
        <Card className="commen-table-Card">
          <Table
            columns={columns}
            dataSource={props.data}
            pagination={false}
            className="commen-table-custom-scrollbar"
          />
          <br />
          <div style={{ float: "right" }}>
            <Pagination
              responsive
              defaultCurrent={props.meta && props.meta.page}
              total={props.meta && props.meta.itemCount}
              pageSize={props.meta && props.meta.take ? props.meta.take : 0}
              onChange={props.PageOnchange}
              pageSizeOptions={[10, 20, 50]}
            />
          </div>
        </Card>
      </div>
      {/* {isOpen ? (
        <ApplicationView
          data={data}
          open={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      ) : null} */}
    </>
  );
}

export default ProductDataTable;
