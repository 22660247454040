import { combineReducers } from "@reduxjs/toolkit";
import measurmentSlice from "./measurmentSlice";
import userSlice from "./userSlice";
import optionSlice from "./optionSlice";
import summarySlice from "./summarySlice";
import productTypeSlice from "./productTypeSlice";
import productSlice from "./productSlice";

const Slices = combineReducers({
  User: userSlice,
  measurements: measurmentSlice,
  accessoriesItem: optionSlice,
  summary: summarySlice,
  productType: productTypeSlice,
  products: productSlice,
});
export default Slices;
