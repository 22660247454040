import { API } from "../config/API";
import { Store } from "../redux/store";

const GET = async (url: any, params: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise((resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const POST = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const PUT = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const S3_IMAGE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(`${API.BASE_URL}${API.S3_IMAGE_UPLOAD}`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const jsonResponse: any = await response.json();
          const obj = {
            ...jsonResponse,
            url: jsonResponse.Location,
            status: true,
          };
          resolve(obj);
        } else {
          let obj = {
            status: false,
            url: null,
          };
          resolve(obj);
        }
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};
// const POST = async (url: any, params: any, token: any) => {
//   return new Promise((resolve, reject) => {
//     fetch(API.BASE_URL + url, {
//       method: "post",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((response) => response.json())
//       .then((json) => {
//         resolve(json);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
export { GET, POST, PUT, S3_IMAGE_UPLOAD };
