import { Card, Tabs, message } from "antd";
import { useState } from "react";
import Header from "../../../../../component/header/header";
import AddProduct from "./product";
import ProductAccessories from "./accessories";
import AdminPageHeader from "../../../../../component/adminPageHeader";
import { API } from "../../../../../config/API";
import { POST } from "../../../../../utils/apiRequest";
import ProductCategory from "./category";
import Measurement from "./measurement";
import { useNavigate } from "react-router-dom";

function Product() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({
    product: null,
    type: null,
    accessories: null,
    Measurement: null,
  });

  const handleTabChange = (nextTab: any) => {
    setActiveTab(nextTab);
  };

  let products: any;
  const handleData = (tab: any, val: any) => {
    if (tab === "1") {
      setProduct({ ...product, product: val });
      products = { ...product, product: val };
    } else if (tab === "2") {
      setProduct({ ...product, type: val });
      products = { ...product, type: val };
    } else if (tab === "3") {
      setProduct({ ...product, accessories: val });
      products = { ...product, accessories: val };
    } else if (tab === "4") {
      setProduct({ ...product, Measurement: val });
      products = { ...product, Measurement: val };
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      let url = API.ADD_PRODUCT;
      const data: any = await POST(url, products);
      if (data) {
        message.success("Successfully add the product");
        // setProduct({ product: null, accessories: null });
        navigate(-1);
        setIsLoading(false);
      } else {
        message.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const data = [
    {
      key: "1",
      tab: "ADD PRODUCT",
      label: <div className="tab-title">ADD PRODUCT</div>,
      children: (
        <AddProduct
          onNext={() => handleTabChange("2")}
          product={(val: any) => handleData("1", val)}
        />
      ),
    },
    {
      key: "2",
      tab: "TYPE",
      label: <div className="tab-title">TYPE</div>,
      children: (
        <ProductCategory
          onNext={() => handleTabChange("3")}
          category={(val: any) => handleData("2", val)}
        />
      ),
    },
    {
      key: "3",
      tab: "ACCESSORIES",
      label: <div className="tab-title">ACCESSORIES</div>,
      children: (
        <ProductAccessories
          onNext={() => handleTabChange("4")}
          accessories={(val: any) => handleData("3", val)}
          loading={isLoading}
        />
      ),
    },
    {
      key: "4",
      tab: "MEASUREMENT",
      label: <div className="tab-title">MEASUREMENT</div>,
      children: (
        <Measurement
          onSubmit={() => onSubmit()}
          measurement={(val: any) => {
            handleData("4", val);
          }}
          loading={isLoading}
        />
      ),
    },
  ];
  return (
    <div className="dataTable-Box1">
      <Header unfixed={true} />
      <br />
      <div className="overFlow-Box1">
        <div className="overFlow-Box2">
          <Card>
            <AdminPageHeader
              title={"CREATE PRODUCT"}
              navigate={"/Auth/product"}
            />
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              {data.map((item) => (
                <Tabs.TabPane key={item.key} tab={item.label}>
                  {item.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Product;
