import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaCirclePlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Header from "../../component/header/header";
import LoadingBox from "../../component/loadingBox";
import PageHeader from "../../component/pageHeader";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";
import { useDispatch } from "react-redux";
import { storeProduct } from "../../redux/slice/productSlice";
import { useSelector } from "react-redux";
import { clearAccessories } from "../../redux/slice/optionSlice";
import { clearMeasurement } from "../../redux/slice/measurmentSlice";

function Product() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [product, setProduct] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products }: any = useSelector((state: any) => {
    return state;
  });

  let selectedImage = products?.products?.id;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    let url = API?.PRODUCT;
    try {
      const data: any = await GET(url, null);
      setProduct(data?.data);
      if (data?.status === true) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleClick = (val: any, data: any) => {
    dispatch(storeProduct(data));
    dispatch(dispatch(clearAccessories()));
    dispatch(dispatch(clearMeasurement()));
    navigate(`/usr/product/${val}/type/${val}`);
  };

  return (
    <>
      <Header hide={true} show={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PageHeader title1={"PRODUCT"} />
      <div className="white-Card" style={{ borderRadius: 0 }}>
        <Container>
          <br />
          <br />
          {isLoading ? <LoadingBox /> : ""}
          <Row>
            {product && product.length > 0 ? (
              product.map((item: any, index: any) => (
                <Col key={index} md={4}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleClick(item?.id, item)}
                  >
                    <img
                      className="Product-Img"
                      style={{
                        borderRadius: "110px",
                        border:
                          selectedImage === item?.id
                            ? "10px solid #dedcdc"
                            : "2px solid #dedcdc",
                      }}
                      src={item?.image}
                      alt={""}
                    />
                    {hoveredIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 34,
                          left: 30,
                        }}
                      >
                        <FaCirclePlus size={30} color="#356bf8" />
                      </div>
                    )}
                    <br />
                    <div
                      className="screen-Txt2"
                      style={{ textAlign: "center" }}
                    >
                      {item?.product_name.toUpperCase()}
                      <br />
                      {selectedImage === item?.id ? "Selected Image" : ""}
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="screen-txt1" style={{ textAlign: "center" }}>
                No products available
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Product;
