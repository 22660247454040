import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import Image from "../assets/images/Maskgroup.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function PageHeader(props: any) {
  const { user } = useSelector((state: any) => state.User);
  console.log(user);
  const { id, productId, type } = useParams();
  const navigate = useNavigate();
  const path = [
    { id: 1, title: props?.title1, path: props?.path1 },
    {
      id: 2,
      title: props?.title2,
      path: props?.path2,
    },
    {
      id: 3,
      title: props?.title3,
      path: props?.path3,
    },
    {
      id: 4,
      title: props?.title4,
      path: props?.path4,
    },
    { id: 5, title: props?.title5, path: "" },
    { id: 6, title: props?.title6, path: "" },
    { id: 7, title: props?.title7, path: "" },
  ];
  return (
    <div className="ProfileCard-Box1">
      <Container>
        <Row align={"middle"}>
          <Col md={18}>
            <Row>
              {path?.map((item: any) => {
                if (item?.title) {
                  return (
                    <div
                      key={item.id}
                      onClick={() => navigate(item?.path)}
                      className="p-2"
                    >
                      <div className="screen-title"> {item.title}</div>
                    </div>
                  );
                }
                return null;
              })}
            </Row>
          </Col>
          <Col md={6}>
            <Row align={"middle"}>
              <Col md={7}>
                <img className="profileCard-Img" src={user?.image} alt="" />
              </Col>
              <Col md={12}>
                <span className="screen-Txt2">
                  {user?.name} <br />
                  <span className="screen-Txt3">{user?.application_no}</span>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageHeader;
