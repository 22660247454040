import { Card, DatePicker, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/header/header";
import { API } from "../../../config/API";
import { GET } from "../../../utils/apiRequest";
import ProductDataTable from "./component/dataTable";
import { Col, Container, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import AdminPageHeader from "../../../component/adminPageHeader";
import LoadingBox from "../../../component/loadingBox";

function ProductScreen() {
  const [page] = useState(1);
  const [take] = useState(10);
  const [product, setProduct] = useState([]);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsloading(true);
    try {
      let filter = `?order=DESC&page=${page}&take=${take}`;
      let url = API.PRODUCT;
      const data: any = await GET(url + filter, null);
      setProduct(data?.data);
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      console.log(err);
    }
  };
  return (
    <div className="dataTable-Box1">
      <Header unfixed={true} />
      <Container>
        <div className="dashboardHeader-Box2">
          <div className="dashboardHeader-Box3">
            <Card>
              <AdminPageHeader
                title={"PRODUCT"}
                button={"CREATE"}
                navigate={"/Auth/add-products"}
              />
              <Row>
                <Col md={3}></Col>
                <Col md={5}>
                  <DatePicker.RangePicker style={{ width: "100%" }} />
                </Col>
                <Col md={4}>
                  <Input suffix={<IoSearchOutline />} placeholder="Search " />
                </Col>
              </Row>
              <br />
              <br />
              {isloading ? <LoadingBox /> : <ProductDataTable data={product} />}
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProductScreen;
