import { createSlice } from "@reduxjs/toolkit";
const optionSlice = createSlice({
  name: "accessoriesItem",
  initialState: {
    options: [],
  },
  reducers: {
    storeAccessories: (state, action) => {
      state.options = action.payload;
    },
    clearAccessories: (state) => {
      state.options = [];
    },
  },
});

export const { storeAccessories, clearAccessories } = optionSlice.actions;
export default optionSlice.reducer;
