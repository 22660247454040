import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyD_b8ynDtuHPZ1XB8Tf3975L8mwBeUxQRs",
  authDomain: "hadaq-9d99e.firebaseapp.com",
  projectId: "hadaq-9d99e",
  storageBucket: "hadaq-9d99e.appspot.com",
  messagingSenderId: "507230149973",
  appId: "1:507230149973:web:631e399a48493b01f023dc",
  measurementId: "G-899J9QY5VQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();
export { Auth, GoogleProvider };
