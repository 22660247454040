import { ConfigProvider } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Navigation from "./navigation";
import ProtectedRoute from "./navigation/protectRoute";
import Accessories from "./screens/accessories";
import BillingScreen from "./screens/billing";
import HistoryScreen from "./screens/historyScreen";
import HomeScreen from "./screens/homeScreen";
import InvoiceScreen from "./screens/invoiceScreen";
import LoginScreen from "./screens/loginScreen";
import MeasurmentScreen from "./screens/measurementScreen";
import Product from "./screens/product";
import ProductTypeScreen from "./screens/productTypeScreen";
import SummeryScreen from "./screens/summaryScreen";
import UpdateProfile from "./screens/updateProfile";
import AdminRegistration from "./screens/adminRegistration";
import OrderDetailsPage from "./screens/homeScreen/detailsPage";

function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "LexendExa-Medium",
            fontSize: 12,
          },
        }}
      >
        <Routes>
          <Route index element={<LoginScreen />} />
          <Route path="adminRegistration" element={<AdminRegistration />} />
          <Route
            path="/usr/*"
            element={
              <ProtectedRoute isSignedIn={User.auth}>
                <Routes>
                  <Route path="/:id" element={<HomeScreen />} />
                  <Route path="/profile/:id" element={<UpdateProfile />} />
                  <Route
                    path="/profile/history/:id"
                    element={<HistoryScreen />}
                  />
                  <Route path="/product/:id" element={<Product />} />
                  <Route
                    path="/product/:productId/type/:type"
                    element={<ProductTypeScreen />}
                  />
                  <Route
                    path="product/:productId/type/:type/measurment"
                    element={<MeasurmentScreen />}
                  />
                  <Route
                    path="product/:productId/type/:type/measurment/summary"
                    element={<SummeryScreen />}
                  />
                  <Route
                    path="product/:productId/type/:type/measurment/summary/accessories"
                    element={<Accessories />}
                  />
                  <Route
                    path="product/:productId/type/:type/measurment/summary/accessories/billing"
                    element={<BillingScreen />}
                  />
                  <Route
                    path="product/:productId/type/:type/measurment/summary/accessories/billing/invoice"
                    element={<InvoiceScreen />}
                  />
                  <Route
                    path="/orderDetails/:id"
                    element={<OrderDetailsPage />}
                  />
                </Routes>
              </ProtectedRoute>
            }
          />
          <Route
            path="/Auth/*"
            element={
              <ProtectedRoute isSignedIn={User.auth}>
                <Navigation />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ConfigProvider>
    </>
  );
}

export default App;
