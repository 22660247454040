import { Row, Col } from "react-bootstrap";
import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function AdminPageHeader(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <Row className="align-items-center">
        <Col md={6}>
          <div className="d-flex align-items-center">
            <div>
              <IoArrowBackCircleOutline
                size={40}
                color="#c2c2c2"
                cursor={"pointer"}
                onClick={() => navigate(props?.navigate)}
              />
            </div>
            <div className="table-Head ">{props?.title}</div>
          </div>
        </Col>
        {props?.button && props.button && (
          <Col md={{ span: 3, offset: 3 }}>
            <Button
              block
              size="large"
              className="primary_bttn3"
              onClick={() => navigate(props?.navigate)}
            >
              {props?.button}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AdminPageHeader;
