import { createSlice } from "@reduxjs/toolkit";
const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
  },
  reducers: {
    storeProduct: (state, action) => {
      console.log(action.payload, "action");

      state.products = action.payload;
    },
    clearProduct: (state) => {
      state.products = [];
    },
  },
});

export const { storeProduct, clearProduct } = productSlice.actions;
export default productSlice.reducer;
