import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../component/pageHeader";
import { storeMeasurement } from "../../redux/slice/measurmentSlice";
import MeasurementOption from "./option";
import { storeAccessories } from "../../redux/slice/optionSlice";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";

function MeasurmentScreen() {
  const { measurements } = useSelector((state: any) => state);
  const { id, productId, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [measurementRedux, setMeasurementRedux] = useState<any>();
  const [form]: any = Form.useForm();

  const handleClick = (val: any) => {
    setMeasurementRedux(val);
  };

  const getMeasurement = async () => {
    try {
      let url = API.GET_ALL_MEASUREMENT_BY_PRODUCTID + productId;
      const response: any = await GET(url, "");
      if (response) {
        let data = response?.data.map((item: any) => {
          form.setFieldsValue({
            length: measurements?.measurement?.length || item?.length,
            shoulder: measurements?.measurement?.shoulder || item?.shoulder,
            chest: measurements?.measurement?.chest || item?.chest,
            upper_hip: measurements?.measurement?.upper_hip || item?.upper_hip,
            hip: measurements?.measurement?.hip || item?.hip,
            sleeve_Opening:
              measurements?.measurement?.sleeve_Opening || item?.sleeve_Opening,
            sleeve_Length:
              measurements?.measurement?.sleeve_Length || item?.sleeve_Length,
            neck: measurements?.measurement?.neck || item?.neck,
            embroidary:
              measurements?.measurement?.embroidary || item?.embroidary,
          });
        });
      } else {
        console.log("error");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMeasurement();
  }, []);

  const onFinish = async (val: any) => {
    try {
      let body = {
        adminId: id,
        productId: productId,
        quantity: val?.quantity,
        meterial: "lenen",
        length: val?.length,
        shoulder: val?.shoulder,
        chest: val?.chest,
        upper_hip: val?.upper_hip,
        hip: val?.hip,
        sleeve_Opening: val?.sleeve_Opening,
        sleeve_Length: val?.sleeve_Length,
        neck: val?.neck,
        embroidary: val?.embroidary,
        delivery_Date: new Date(),
      };

      dispatch(storeMeasurement(body));
      dispatch(storeAccessories(measurementRedux));

      navigate(`/usr/product/${productId}/type/${type}/measurment/summary`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClear = (fieldName: any) => {
    form.setFieldsValue({ [fieldName]: "" });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <div className="">
        <PageHeader
          title1={"PRODUCT"}
          path1={`/usr/product/${productId}`}
          title2={"TYPE"}
          path2={`/usr/product/${productId}/type/${productId}`}
          title3={"MEASUREMENT"}
          path3={`/usr/product/${productId}/type/${productId}/measurment`}
        />
        <Container>
          <br />
          <br />
          <br />
          <br />
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className="screen-Txt2">THAWB MEASUREMENTS</div>
              <br />
              <br />
              <div className="formItem">
                <Form.Item name={"length"}>
                  <Input
                    style={{ borderRadius: 30 }}
                    type="number"
                    placeholder="LENGTH"
                    suffix={<RxCross1 onClick={() => handleClear("length")} />}
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"shoulder"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="SHOULDER"
                    suffix={
                      <RxCross1 onClick={() => handleClear("shoulder")} />
                    }
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"chest"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="CHEST"
                    suffix={<RxCross1 onClick={() => handleClear("chest")} />}
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"upper_hip"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="UPPER HIP"
                    suffix={
                      <RxCross1 onClick={() => handleClear("upper_hip")} />
                    }
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"hip"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="HIP"
                    suffix={<RxCross1 onClick={() => handleClear("hip")} />}
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"sleeve_Length"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="SLEEVE LENGTH"
                    suffix={
                      <RxCross1 onClick={() => handleClear("sleeve_Length")} />
                    }
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"sleeve_Opening"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="SLEEVE OPENING"
                    suffix={
                      <RxCross1 onClick={() => handleClear("sleeve_Opening")} />
                    }
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"neck"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="NECK"
                    suffix={<RxCross1 onClick={() => handleClear("neck")} />}
                  />
                </Form.Item>
              </div>

              <div className="formItem">
                <Form.Item name={"embroidary"}>
                  <Input
                    type="number"
                    style={{ borderRadius: 30 }}
                    placeholder="EMBROIDERY"
                    suffix={
                      <RxCross1 onClick={() => handleClear("embroidary")} />
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col
              lg={{ span: 6, offset: 1 }}
              md={{ span: 6, offset: 1 }}
              sm={12}
              xs={12}
            >
              <div className="screen-Txt2">OPTIONS</div>
              <MeasurementOption onOption={(val: any) => handleClick(val)} />
            </Col>
          </Row>
          <Col md={{ span: 2, offset: 10 }}>
            <Button
              block
              size="large"
              className="primary_bttn3"
              htmlType="submit"
            >
              SAVE
            </Button>
          </Col>
        </Container>

        <br />
        <br />
      </div>
    </Form>
  );
}

export default MeasurmentScreen;
