import { Button, Input, Form } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiUploadCloud } from "react-icons/fi";
import ImagePicker from "../../../../../component/imagePIcker";

function AddProduct(props: any) {
  const [toggle, toggleModal] = useState(false);
  const [image, setImage] = useState();

  const handleImageUpdated = (val: any) => {
    setImage(val);
  };

  const onFinish = async (val: any) => {
    const obj = {
      description: val.description,
      information: val.information,
      price: val.price,
      product_name: val.product_name,
      image: image,
    };
    try {
      props.product(obj);
      props?.onNext();
    } catch (err) {
      console.log(err);
    }
  };
  const { TextArea } = Input;

  return (
    <>
      <Form onFinish={onFinish}>
        <Row>
          <Col md={6}>
            <Col md={12}>
              <label className="formLabel">Name</label>
              <Form.Item
                name={"product_name"}
                rules={[{ required: true, message: "" }]}
                style={{ height: 28 }}
              >
                <Input size="large" style={{ borderRadius: 30 }} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <label className="formLabel">Description</label>
              <Form.Item
                name={"description"}
                rules={[{ required: true, message: "" }]}
                style={{ height: 28 }}
              >
                <Input size="large" style={{ borderRadius: 30 }} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <label className="formLabel">Price</label>
              <Form.Item
                name={"price"}
                rules={[{ required: true, message: "" }]}
                style={{ height: 28 }}
              >
                <Input size="large" style={{ borderRadius: 30 }} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <label className="formLabel">Information</label>
              <Form.Item name={"information"}>
                <TextArea />
              </Form.Item>
            </Col>
          </Col>

          <Col md={6}>
            <Col md={{ span: 8 }}>
              {image ? (
                <div className="ImageUpload-Box1">
                  <img className="imageUpload-Img" src={image} alt="" />
                </div>
              ) : (
                <div className="ImageUpload-Box1">
                  <div className="ImageUpload-Box2">
                    <FiUploadCloud size={50} />
                    <div className="imageUpload-Txt1">UPLOAD IMAGE HERE</div>

                    <div>
                      <Button onClick={() => toggleModal(true)}>
                        Choose file . . .
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <br />
            <br />
            <Col md={6}></Col>
            <Col md={{ span: 6, offset: 6 }}>
              <Button
                block
                size="large"
                htmlType="submit"
                className="primary_bttn3"
              >
                Add Product
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
      <ImagePicker
        open={toggle}
        modalClose={() => toggleModal(false)}
        onImageUpdated={(data: any) => handleImageUpdated(data)}
      />
    </>
  );
}

export default AddProduct;
