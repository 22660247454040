import React from "react";
import NoDataFound from "../../component/noDataFound";
import Header from "../../component/header/header";
import OrderList from "./order";
import { Container } from "react-bootstrap";
import "./styles.scss";
import { useTranslation } from "react-i18next";
function HistoryScreen() {
  const { t } = useTranslation();
  return (
    <>
      <Header hide={true} show={true} />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container>
        <div className="table-Head">{t("orderHistory.title.heading")}</div>
        <br />
        <OrderList />
      </Container>
    </>
  );
}

export default HistoryScreen;
