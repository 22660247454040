import { Button, Form, Input } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCirclePlus } from "react-icons/fa6";
import { FiUploadCloud } from "react-icons/fi";
import ImagePicker from "../../../../../component/imagePIcker";

function ProductCategory(props: any) {
  const [toggle, toggleModal] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);

  const handleImageUpdated = (val: any, index: number) => {
    const updatedImages: any = [...images];
    updatedImages[index] = val;
    setImages(updatedImages);
  };

  const onFinish = async (val: any) => {
    try {
      const result = val?.category?.map((obj: any, index: any) => ({
        ...obj,
        image: images[index],
      }));
      props.category(result);
      props?.onNext();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <br />
      <Form onFinish={onFinish} initialValues={{ category: [{}] }}>
        <Form.List name={"category"}>
          {(fields, { add, remove }) => (
            <>
              <Row className="">
                {fields.map((fields, index) => {
                  const hasImage = images[index];

                  return (
                    <>
                      <Col md={3}>
                        <label className="formLabel">Category Type</label>
                        <Form.Item name={[fields.name, "category_name"]}>
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          {hasImage ? (
                            <div className="">
                              <img
                                className="imageUpload-Img"
                                src={hasImage}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className="">
                              <div className="ImageUpload-Box2">
                                <FiUploadCloud size={50} />
                                <div className="imageUpload-Txt1">
                                  UPLOAD IMAGE HERE
                                </div>
                                <div>
                                  <Button onClick={() => toggleModal(true)}>
                                    Choose file . . .
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  );
                })}

                <Col md={3}>
                  <label className="formLabel"></label>
                  <Form.Item></Form.Item>
                  <div className="ImageUpload-Box2" onClick={() => add()}>
                    <FaCirclePlus size={30} color="grey" />
                    <div className="imageUpload-Txt1">ADD</div>
                  </div>
                </Col>
                {/* <Col md={3}>
                  <div className="ImageUpload-Box2">
                    <FaCirclePlus size={30} color="grey" />
                    <div className="imageUpload-Txt1">REMOVE</div>
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col md={6}></Col>
                {/* <Col md={3}>
                  <Button
                    block
                    size="large"
                    className="primary_bttn4"
                    onClick={() => add()}
                  >
                    ADD MORE
                  </Button>
                </Col> */}
                <Col md={{ span: 2, offset: 10 }}>
                  <Button
                    block
                    size="large"
                    className="primary_bttn3"
                    htmlType="submit"
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
      <ImagePicker
        open={toggle}
        modalClose={() => toggleModal(false)}
        onImageUpdated={(data: any) => handleImageUpdated(data, images.length)}
      />
    </>
  );
}

export default ProductCategory;
