import { Button, Card, Input, Pagination, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import { TbReload } from "react-icons/tb";

function UserDataTable(props: any) {
  console.log("===============", props);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: <p className="table-Title">#</p>,
      dataIndex: "id",
      key: "id",

      render: (index: any) => {
        console.log(index);
        return (
          <div className="table-Txt">
            {(props.meta.page - 1) * props.meta.take + index}
          </div>
        );
      },
    },
    {
      title: <p className="table-Title">User</p>,
      dataIndex: "name",
      key: "name",

      render: (record: any) => {
        return <div className="table-Txt">{record.toUpperCase()}</div>;
      },
    },
    {
      title: <p className="table-Title">Contact no</p>,
      dataIndex: "phone_no",
      key: "phone_no",

      render: (record: any) => {
        return <div className="table-Txt">{`${record.toUpperCase()}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Email</p>,
      dataIndex: "email",
      key: "email",

      render: (record: any) => {
        return <div className="table-Txt">{`${record.toUpperCase()}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Address</p>,
      dataIndex: "address",
      key: "address",

      render: (record: any) => {
        return <div className="table-Txt">{record.toUpperCase()}</div>;
      },
    },

    {
      title: <p className="table-Title">Action</p>,

      key: "action",
      render: (_, record) => {
        return (
          <div className="table-Action">
            {/* <div className="">
              <Space size="middle">
                <FaEye
                  size={20}
                  color="#2c698d"
                  cursor="pointer"
                  // onClick={() => handleClick(record, "view")}
                  onClick={() => props.onEdit(record, "view")}
                />
              </Space>
            </div> */}
            <div className="">
              <Space size="middle">
                <RiPencilFill
                  size={20}
                  color="#2c698d"
                  cursor="pointer"
                  // onClick={() => handleClick(record, "edit")}
                  onClick={() => props.onEdit(record, "edit")}
                />
              </Space>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="">
        <Card className="commen-table-Card">
          <Table
            columns={columns}
            dataSource={props.data}
            pagination={false}
            className="commen-table-custom-scrollbar"
          />
          <br />
          <div style={{ float: "right" }}>
            <Pagination
              responsive
              defaultCurrent={props.meta && props.meta.page}
              total={props.meta && props.meta.itemCount}
              pageSize={props.meta && props.meta.take ? props.meta.take : 0}
              onChange={props.PageOnchange}
              pageSizeOptions={[10, 20, 50]}
            />
          </div>
        </Card>
      </div>
      {/* {isOpen ? (
        <ApplicationView
          data={data}
          open={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      ) : null} */}
    </>
  );
}

export default UserDataTable;
