import { createSlice } from "@reduxjs/toolkit";
const measurementSlice = createSlice({
  name: "measurement",
  initialState: {
    measurement: [],
  },
  reducers: {
    storeMeasurement: (state, action) => {
      state.measurement = action.payload;
    },
    clearMeasurement: (state) => {
      state.measurement = [];
    },
  },
});

export const { storeMeasurement, clearMeasurement } = measurementSlice.actions;
export default measurementSlice.reducer;
