import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../component/header/header";
import UserDataTable from "./dataTable";
import { API } from "../../../config/API";
import { GET } from "../../../utils/apiRequest";
import { Card, DatePicker, Input } from "antd";
import LoadingBox from "../../../component/loadingBox";
import AdminPageHeader from "../../../component/adminPageHeader";
import { IoSearchOutline } from "react-icons/io5";

function UserScreen() {
  const [page] = useState(1);
  const [take] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    try {
      let filter = `?order=DESC&page=${page}&take=${take}`;
      let url = API.USERS;
      const data: any = await GET(url + filter, null);
      setData(data?.data);
      setMeta(data?.meta);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="dashBoard-Box1">
        <Header unfixed={true} />
        <div className="dashboardHeader-Box2">
          <div className="dashboardHeader-Box3">
            <br />

            <Card bordered={false}>
              <AdminPageHeader title={"USER"} navigate={"/Auth"} />
              <Row>
                <Col md={3}></Col>
                <Col md={5}>
                  <DatePicker.RangePicker style={{ width: "100%" }} />
                </Col>
                <Col md={4}>
                  <Input suffix={<IoSearchOutline />} placeholder="Search " />
                </Col>
              </Row>
              <br />
              <br />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <UserDataTable
                  data={data}
                  meta={meta}
                  onEdit={() => console.log("EDIT THE USERS....")}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserScreen;
