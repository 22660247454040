import { Button, Col, Form, Row } from "antd";
import moment from "moment";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JubbaImg from "../../assets/images/jubba.png";
import PageHeader from "../../component/pageHeader";
import { storeSummary } from "../../redux/slice/summarySlice";

function SummeryScreen() {
  const { productId, type } = useParams();
  const { accessoriesItem, measurements, productType, products } = useSelector(
    (state: any) => {
      return state;
    }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const measurement = measurements?.measurement;
  const option = accessoriesItem?.options;

  const measurementData = [
    { type: "LENGTH", size: measurement?.length },
    { type: "SHOULDER", size: measurement?.shoulder },
    { type: "CHEST", size: measurement?.chest },
    { type: "UPPER HIP", size: measurement?.upper_hip },
    { type: "HIP", size: measurement?.hip },
    { type: "SLEEVE LENGTH", size: measurement?.sleeve_Length },
    { type: "SLEEVE OPENING", size: measurement?.sleeve_Opening },
    { type: "NECK", size: measurement?.neck },
    { type: "EMBROIDARY", size: measurement?.embroidary },
  ];

  const items = [
    { name: "THAWB TYPE", type: products?.products?.product_name },
    { name: "PRICE", type: products?.products?.price },
    { name: "PRODUCT TYPE", type: productType?.productType?.assessoryName },
    { name: "QUANTITY", type: "1" },
    { name: "DELIVERY DATE", type: "FEBRUARY 31, 2024" },
  ];

  const accessories = [
    { image: option?.image },
    { image: option?.image },
    { image: option?.image },
    { image: option?.image },
    { image: option?.image },
  ];

  const handleSumbit = () => {
    dispatch(storeSummary(accessories));
    navigate(
      `/usr/product/${productId}/type/${type}/measurment/summary/accessories`,
      { state: { data: option } }
      // `usr/profile/${id}/product/:${productId}/producttype/:${type}/measurment/summary/accessories`
    );
  };

  return (
    <>
      <PageHeader
        title1={"PODUCT"}
        path1={`/usr/product/${productId}`}
        title2={"TYPE"}
        path2={`/usr/product/${productId}/type/${productId}`}
        title3={"MEASUREMENT"}
        path3={`/usr/product/${productId}/type/${productId}/measurment`}
        title4={"SUMMARY"}
      />
      <br />
      <br />
      <br />
      <Container>
        <Form>
          <Row>
            <Col lg={8} md={8}>
              <div className="screen-Txt2">SUMMARY</div>
              <br />
              <br />
              <div className="screen-Txt4 screen-Txt3">MEASUREMENTS</div>
              <br />
              {measurementData?.map((item: any) => {
                return (
                  <Row align={"middle"}>
                    <Col lg={12} className="screen-Txt4">
                      {item?.type}
                    </Col>
                    <Col lg={12} className="screen-Txt4">
                      {item?.size}
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col lg={8} md={8}>
              <br />
              <br />
              {/* <div
                style={{
                  backgroundImage: `url(${JubbaImg})`,
                  backgroundSize: "cover",
                  width: "264px",
                  height: "305px",
                  position: "relative",
                }}
              ></div> */}
              <img className="MeasurmentScreen-Img" src={JubbaImg} alt="" />
            </Col>
            <Col lg={8} md={8}>
              <div className="screen-Txt2">
                DATE {moment(new Date()).format("DD MM YYYY")}
              </div>
              <br />
              <br />
              {items?.map((item: any) => {
                return (
                  <>
                    <Row align={"middle"}>
                      <Col lg={12} className="screen-Txt4 screen-Txt3">
                        {item?.name}
                      </Col>
                      <Col lg={12} className="screen-Txt4 ">
                        {item?.type}
                      </Col>
                    </Row>
                  </>
                );
              })}
              <br />
              <Row gutter={6}>
                {accessoriesItem?.options?.map((item: any) => {
                  return (
                    <Col lg={5}>
                      <div className="screen-vectorCard">
                        <img
                          className="MeasurmentScreen-Img"
                          style={{ height: "50px" }}
                          src={item?.image}
                          alt=""
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <br />
              <br />
              <br />
              <Row justify={"space-between"}>
                <Col md={7}>
                  <Button block size="large" className="primary_bttn3">
                    SHARE
                  </Button>
                </Col>
                <Col md={7}>
                  <Button
                    block
                    size="large"
                    className="primary_bttn4"
                    // htmlType="submit"
                    // loading={loading}
                    // onClick={() =>
                    // navigate(
                    //   "usr/profile/product/producttype/measurment/summary/accessories"
                    // )
                    // }
                    onClick={() => handleSumbit()}
                  >
                    CONFIRM
                  </Button>
                </Col>
                <Col md={7}>
                  <Button block size="large" className="primary_bttn4">
                    PRINT
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default SummeryScreen;
