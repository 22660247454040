import { Column } from "@ant-design/plots/es";
import { useEffect, useState } from "react";

const GraphChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/antfincdn/PC3daFYjNw/column-data.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const calcAverageValue: any = (data: any, type: any) => {
    const items = data.filter((d: any) => d.type === type);
    return items.length
      ? items.reduce((a: any, b: any) => a + b.value, 0) / items.length
      : "-";
  };

  const config = {
    data,
    xField: "city",
    yField: "value",
    seriesField: "type",
    isGroup: "true",
    legend: {
      position: "right-top",
      offsetX: 8,
      title: {
        text: "ENGLISH）",
        spacing: 8,
      },
      itemName: {
        style: (item: any, index: any) => {
          return {
            fill: calcAverageValue(data, item.value) > 10000 ? "red" : "green",
          };
        },
      },
      itemValue: {
        formatter: (text: any, item: any) => {
          return calcAverageValue(data, item.value);
        },
        style: (item: any, index: any) => {
          return {
            fill: calcAverageValue(data, item.value) > 10000 ? "red" : "green",
          };
        },
      },
    },
  };

  return <Column style={{ height: 300 }} {...config} />;
};
export { GraphChart };
// ReactDOM.render(<GraphChart />, document.getElementById("container"));
