import { Col, Row } from "react-bootstrap";
import { GraphChart } from "../../../component/graphChart";
// import GraphChart from "../../../component/graphChart";
function TotalSalesAndProfits() {
  return (
    <>
      <div className="white-Card">
        <Row>
          <Col md={5} style={{ borderRight: "1px solid black" }}>
            <div className="dashBoard-Txt1">TOTAL SALES</div>
            <div className="dashBoard-Txt2">
              27358<span className="dashBoard-txt3"> SAR</span>
            </div>
            <div style={{ height: 200 }}>
              <GraphChart />
            </div>
          </Col>
          <Col md={7}>
            <Col
              md={12}
              style={{ borderBottom: "1px solid black", padding: 10 }}
            >
              <div className="dashBoard-Txt1">TOTAL SALES</div>
              <div className="dashBoard-Txt2">
                27358<span className="dashBoard-txt3"> SAR</span>
              </div>
            </Col>
            <Col md={12} style={{ padding: 10 }}>
              <div className="dashBoard-Txt1">TOTAL SALES</div>
              <div className="dashBoard-Txt2">
                27358<span className="dashBoard-txt3"> SAR</span>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TotalSalesAndProfits;
