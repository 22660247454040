import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Button, Form, Input, Select, message, notification } from "antd";
import { InputOTP } from "antd-input-otp";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Header from "../../component/header/header";
import { API } from "../../config/API";
import CountryCode from "../../config/countryCode.json";
import { Auth } from "../../config/fireBase";
import { login } from "../../redux/slice/userSlice";
import { POST } from "../../utils/apiRequest";
import Existing from "../loginScreen/component/existingCustomer";
import { RxCross2 } from "react-icons/rx";
import NewCustomer from "../loginScreen/component/newCustomer";
const AdminRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verification, setverification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [existing, setExisting] = useState(false);
  const [error, setError] = useState<any>(null);
  const [switchValue, setSwitchValue] = useState<any>(true);

  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      setData(val);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {
        size: "invisible",
      });
      let phone = `${val.code}${val.phonenumber}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
        setIsLoading(false);
      } else {
        setError("Verification Failed");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOtp = async (val: any) => {
    try {
      setIsLoading(true);
      let otp = val.otp.join("");
      let verify = await autho.confirm(otp);
      if (verify?.user?.phoneNumber) {
        signUp();
      } else {
        message.error("Incorrect OTP");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const signUp = async () => {
    let body = {
      name: data?.name,
      email: data?.email,
      countrycode: data?.code,
      phone_no: data?.phonenumber,
      mobileverified: 1,
      image: "",
      address: "",
      role: "admin",
    };
    try {
      setIsLoading(true);
      let url = API.REGISTER;
      const data: any = await POST(url, body);
      if (data?.status === true) {
        notification.success({
          message: data?.message,
        });
        onLogin();
      } else {
        setError(data?.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onLogin = async () => {
    setIsLoading(true);
    try {
      let url = API.LOGIN;
      let body = {
        name: data?.name,
        phone_no: data?.phonenumber,
      };

      const resp: any = await POST(url, body);
      if (resp.status === true) {
        dispatch(login(resp?.data));
        notification.success({
          message: resp?.message,
        });
        navigate(
          resp?.data?.mobileverified && resp?.data?.role === "admin"
            ? "/Auth"
            : ""
        );
        setIsLoading(false);
      } else {
        setError(resp?.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 85 }} showSearch={true}>
        {CountryCode.map((item: any) => (
          <Select.Option key={item} value={item.dial_code}>
            {item.dial_code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <div className="adminRegister-Box1">
        <div
          className=""
          style={{
            height: 100,
            paddingTop: 24,
          }}
        >
          <Header
            fixed={true}
            background={true}
            hide={existing ? true : false}
            switchValue={setSwitchValue}
          />
        </div>
        <Container>
          <Row className="g-0">
            <Col md={6}>
              <div className="adminRegister-Box2">
                <div className="adminRegister-Txt1" style={{ color: "black" }}>
                  {t("loginScreen.title.company_name_arb")}
                </div>
                <div className="adminRegister-Txt2" style={{ color: "black" }}>
                  {t("loginScreen.title.company_name")}
                </div>
              </div>
            </Col>
            <Col md={6}>
              {switchValue ? (
                <div className="adminRegister-Box3">
                  {existing ? (
                    <Existing open={true} onClose={() => setExisting(false)} />
                  ) : (
                    <Form
                      onFinish={verification ? verifyOtp : onFinish}
                      initialValues={{ code: "+91" }}
                    >
                      <div className="adminRegister-Box4">
                        {verification ? (
                          <>
                            <div className="adminRegister-Box6">
                              <div className="adminRegister-Txt7">
                                {t("loginScreen.title.enter_Otp")}
                              </div>
                              <div>
                                <RxCross2
                                  cursor={"pointer"}
                                  color="black"
                                  size={34}
                                  onClick={() => setverification(false)}
                                />
                              </div>
                            </div>
                            <br />
                          </>
                        ) : (
                          <>
                            <div className="adminRegister-Txt3">
                              {t("loginScreen.title.create_Account")}
                            </div>
                            <br />
                            <div className="adminRegister-Txt4">
                              {t("loginScreen.title.create_Account_Detail")}
                              <br />
                              {t("loginScreen.title.create_Account_Detail1")}
                            </div>
                          </>
                        )}
                        <br />
                        {verification ? (
                          <>
                            <Form.Item
                              name="otp"
                              rules={[
                                {
                                  required: true,
                                  message: "Input 6 digit verification code !",
                                },
                              ]}
                              className="d-flex justify-content-center mb-2"
                            >
                              <InputOTP
                                autoFocus
                                inputType="numeric"
                                inputStyle={{ maxWidth: 56 }}
                              />
                            </Form.Item>
                            {error ? (
                              <>
                                <div
                                  className="error_Txt"
                                  style={{ textAlign: "center" }}
                                >
                                  {error}
                                </div>
                                <br />
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name={"name"}
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("loginScreen.title.input_name")}
                                className="input_radius"
                              />
                            </Form.Item>
                            <Form.Item
                              name={"email"}
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("loginScreen.title.input_mail")}
                                className="input_radius"
                              />
                            </Form.Item>

                            <Form.Item
                              name={"phonenumber"}
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                              style={{
                                border: "1px solid #d9d9d9",
                                borderRadius: 30,
                                paddingTop: 5,
                                height: 63,
                              }}
                            >
                              <Input
                                addonBefore={Selector}
                                placeholder={t("loginScreen.title.input_phone")}
                                type="number"
                                bordered={false}
                                size="large"
                                style={{
                                  paddingTop: 5,
                                }}
                              />
                            </Form.Item>
                            <div id="recaptcha"></div>
                          </>
                        )}
                        <Col md={{ span: 10, offset: 1 }}>
                          <Button
                            className="primary_bttn1"
                            block
                            size="large"
                            htmlType="submit"
                            loading={isLoading}
                          >
                            {verification
                              ? t("loginScreen.title.submit")
                              : t("loginScreen.title.register")}
                          </Button>
                          <br />
                          <br />
                          <div className="adminRegister-Txt5">
                            {t("loginScreen.title.already_Account")} &nbsp;
                            <a
                              className="adminRegister-Txt6"
                              onClick={() => setExisting(true)}
                            >
                              {t("loginScreen.title.sign")}
                            </a>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  )}
                </div>
              ) : (
                // <div className="adminRegister-Box7">
                //   <NewCustomer open={true} switchValue={setSwitchValue} />
                // </div>
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminRegistration;
