import { Button, Form, Input } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { HiMinusCircle } from "react-icons/hi2";
import ImagePicker from "../../../../../component/imagePIcker";
import Table from "react-bootstrap/Table";

function ProductAccessories(props: any) {
  const [form] = Form.useForm();
  const [toggle, toggleModal] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [imagesObj, setImagesObj] = useState<any>({});
  const [number, setNumber] = useState<any>();

  const handleImageUpdated = (
    val: any,
    index: any,
    index1: any,
    index2: any
  ) => {
    const updatedImages: any = [...images];
    updatedImages[index] = val;
    setImages(updatedImages);
    const values = form.getFieldsValue();
    let key =
      values?.accessories[index1]?.varaint[index2]?.brand + index
        ? values?.accessories[index1]?.varaint[index2]?.brand + index // values?.accessories[index1]?.varaint[index2]?.brand
        : index;

    setImagesObj((prevState: any) => {
      const updatedState = { ...prevState };
      updatedState[key] = val;
      return updatedState;
    });
  };

  const handleImageRemoval = (indexToRemove: number) => {
    const updatedImages = [...images];
    updatedImages[indexToRemove] = null;
    setImages(updatedImages);
  };

  const onFinish = async (val: any) => {
    try {
      const result = val?.accessories?.map((obj: any, index: any) => {
        let varaint = obj?.varaint?.map((item: any, index1: number) => ({
          ...item,
          // image: imagesObj[item?.brand],
          image:
            imagesObj[
              item?.brand + [Number(index.toString() + index1.toString())]
            ],
        }));
        return { ...obj, varaint };
      });
      props?.accessories(result);
      props?.onNext();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{ accessories: [{}] }}
      >
        <Form.List name={"accessories"}>
          {(fields, { add, remove }) => (
            <>
              <div style={{ textAlign: "end" }}>
                <Button onClick={() => add()}>+ Add Varaint</Button>
              </div>
              <Row className="align-items-center">
                {fields.map((fields, index) => {
                  return (
                    <>
                      <Row>
                        <Col md={6}>
                          <Col md={12}>
                            <label className="formLabel">Item Name</label>
                            <Form.Item
                              style={{ height: 25 }}
                              name={[fields.name, "accessory_name"]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.List name={[fields.name, "varaint"]}>
                              {(subFields, subOpt) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Col md={12}>
                                    <div style={{ textAlign: "end" }}>
                                      <Button
                                        type="dashed"
                                        onClick={() => subOpt.add()}
                                      >
                                        + Add Accesseries
                                      </Button>
                                    </div>
                                  </Col>
                                  <br />
                                  {subFields.map(
                                    (subField, subIndex: any, ...restField) => {
                                      const hasImage =
                                        images[
                                          Number(
                                            index.toString() +
                                              subIndex?.toString()
                                          )
                                        ];
                                      setNumber(
                                        Number(
                                          index.toString() + subIndex.toString()
                                        )
                                      );

                                      return (
                                        <Table
                                          className="table table-hover"
                                          responsive
                                          size="sm"
                                        >
                                          <thead>
                                            <label className="formLabel">
                                              Type {subField.name + 1}
                                            </label>
                                            <tr>
                                              <th scope="col">Name</th>
                                              <th scope="col">Brand</th>
                                              <th scope="col">Barcode</th>
                                              <th scope="col">Price</th>
                                              <th scope="col">Image</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <Form.Item
                                                  {...restField}
                                                  name={[subField.name, "name"]}
                                                >
                                                  <Input placeholder="Name" />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item
                                                  {...restField}
                                                  required
                                                  name={[
                                                    subField.name,
                                                    "brand",
                                                  ]}
                                                >
                                                  <Input placeholder="Brand" />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item
                                                  {...restField}
                                                  name={[
                                                    subField.name,
                                                    "barcode",
                                                  ]}
                                                >
                                                  <Input placeholder="Barcode" />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item
                                                  {...restField}
                                                  name={[
                                                    subField.name,
                                                    "price",
                                                  ]}
                                                >
                                                  <Input
                                                    type="number"
                                                    placeholder="Price"
                                                  />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                {hasImage ? (
                                                  <div
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        objectFit: "cover",
                                                        borderRadius: "15px",
                                                      }}
                                                      src={hasImage}
                                                      alt=""
                                                    />
                                                    <div
                                                      style={{
                                                        position: "absolute",
                                                        top: -2,
                                                        right: -1,
                                                      }}
                                                    >
                                                      <HiMinusCircle
                                                        color="red"
                                                        size={20}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleImageRemoval(
                                                            number
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <Form.Item
                                                      {...restField}
                                                      name={[
                                                        subField.name,
                                                        "image",
                                                      ]}
                                                    >
                                                      <Button
                                                        onClick={() =>
                                                          toggleModal(true)
                                                        }
                                                      >
                                                        Upload image
                                                      </Button>
                                                    </Form.Item>
                                                    <ImagePicker
                                                      open={toggle}
                                                      modalClose={() =>
                                                        toggleModal(false)
                                                      }
                                                      onImageUpdated={(
                                                        data: any
                                                      ) => {
                                                        handleImageUpdated(
                                                          data,
                                                          number,
                                                          index,
                                                          subIndex
                                                        );
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() => {
                                                    subOpt.remove(subIndex);
                                                  }}
                                                >
                                                  remove
                                                </Button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </Form.List>
                          </Col>
                        </Col>
                        <Col md={6} className="align-items-center">
                          {index > 0 && (
                            <div style={{ textAlign: "end" }}>
                              <Button
                                onClick={() => remove(fields?.name)}
                                style={{
                                  backgroundColor: "red",
                                  color: "#fff",
                                  border: "none",
                                }}
                              >
                                - Remove Varaint
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <hr />
                      <br />
                      <br />
                    </>
                  );
                })}
              </Row>
            </>
          )}
        </Form.List>
        <Row>
          <Col md={6}></Col>
          <Col md={3}></Col>
          <Col md={3}>
            <Button
              block
              size="large"
              className="primary_bttn3"
              htmlType="submit"
              loading={props?.loading}
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default ProductAccessories;
