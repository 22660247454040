import {
  Button,
  Card,
  Input,
  Pagination,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { FaEye } from "react-icons/fa";
import { API } from "../../../config/API";
import { PUT } from "../../../utils/apiRequest";
import { useNavigate } from "react-router";

function OrdersTable(props: any) {
  const navigate = useNavigate();
  const onChange = async (value: any, id: string) => {
    await UpdateStatus(id, value);
    props?.api();
  };

  const UpdateStatus = async (id: string, value: any) => {
    try {
      let obj = {
        status: value,
      };
      let url = API.UPDATE_ORDER_STATUS + "/" + id;
      const response = await PUT(url, obj);

      if (response) {
        message.success("Successfully Updated The Status");
      } else {
        message.error("Something Went Wrong !!");
      }
    } catch (error) {
      console.error("Error while updating status:", error);
    }
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: <p className="table-Title">id</p>,
      dataIndex: "id",
      key: "id",

      render: (record: any) => {
        console.log(record);
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: <p className="table-Title">adminId</p>,
      dataIndex: "adminId",
      key: "adminId",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">productId</p>,
      dataIndex: "productId",
      key: "productId",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Product Name</p>,
      dataIndex: "productName",
      key: "productName",
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Product Image</p>,
      dataIndex: ["product_Ordered", "image"],
      key: "image",
      render: (record: any) => {
        return (
          <div className="table-Txt">
            <img
              src={record}
              alt="Product Image"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        );
      },
    },
    {
      title: <p className="table-Title">Product Type Name</p>,
      dataIndex: ["productTypeName"],
      key: "productTypeName",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Product Type Image</p>,
      dataIndex: ["productType"],
      key: "productType",
      render: (record: any) => {
        return (
          <div className="table-Txt">
            <img
              src={record}
              alt="Neck Type"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        );
      },
    },
    {
      title: <p className="table-Title">neck_type</p>,
      dataIndex: "neck_type",
      key: "neck_type",
      render: (record: any) =>
        record.map((item: any) => (
          <div key={item}>
            <img
              src={item}
              alt="Neck Type"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        )),
    },

    // return Array?.isArray(JSON?.parse(record))
    //   ? JSON?.parse(record)?.map((item: string) => {
    //       return (
    // <div>
    //   <img
    //     src={item}
    //     alt="Neck Type"
    //     style={{ width: "50px", height: "50px" }}
    //   />
    // </div>
    //       );
    //     })
    //   : null;
    {
      title: <p className="table-Title">Price</p>,
      dataIndex: ["product_Ordered", "price"],
      key: "price",
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Information</p>,

      dataIndex: ["product_Ordered", "information"],
      key: "information",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">Description</p>,

      dataIndex: ["product_Ordered", "description"],
      key: "description",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },

    {
      title: <p className="table-Title">delivery_Date</p>,
      dataIndex: "delivery_Date",
      key: "delivery_Date",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },

    {
      title: <p className="table-Title">shoulder</p>,
      dataIndex: "shoulder",
      key: "shoulder",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">sleevopening</p>,
      dataIndex: "sleeve_Opening",
      key: "sleeve_Opening",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },

    {
      title: <p className="table-Title">hip</p>,
      dataIndex: "hip",
      key: "hip",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">upperhip</p>,
      dataIndex: "upper_hip",
      key: "upper_hip",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">neck</p>,
      dataIndex: "neck",
      key: "neck",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">sleevlength</p>,
      dataIndex: "sleeve_Length",
      key: "sleeve_Length",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">embriodery</p>,
      dataIndex: "embriodery",
      key: "embriodery",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },

    {
      title: <p className="table-Title">pocket_type</p>,
      dataIndex: "pocket_type",
      key: "pocket_type",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">assessory_name</p>,
      dataIndex: "assessory_name",
      key: "assessory_name",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">varaint_name</p>,
      dataIndex: "varaint_name",
      key: "varaint_name",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">varaint_price</p>,
      dataIndex: "varaint_price",
      key: "varaint_price",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },

    {
      title: <p className="table-Title">cuffs_type</p>,
      dataIndex: "cuffs_type",
      key: "cuffs_type",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">gusset_type</p>,
      dataIndex: "gusset_type",
      key: "gusset_type",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">barcode</p>,
      dataIndex: ["product_Ordered", "barcode"],
      key: "barcode",
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">varaint_image</p>,
      dataIndex: "varaint_image",
      key: "varaint_image",

      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: <p className="table-Title">status</p>,
      dataIndex: "status",
      key: "status",

      render: (record: any) => {
        return (
          <div className="table-Txt">
            {record == 1 ? (
              <Tag color="red">Pending</Tag>
            ) : record == 2 ? (
              <Tag color="green">Delivered</Tag>
            ) : record == 3 ? (
              <Tag color="geekblue">Progress</Tag>
            ) : null}
          </div>
        );
      },
    },
    {
      title: <p className="table-Title">change Status</p>,
      render: (record: any) => {
        return (
          <div className="table-Txt">
            <Select
              onChange={(value) => onChange(value, record.id)}
              style={{ width: "100Px" }}
            >
              <Select.Option value="1">Pending</Select.Option>
              <Select.Option value="2">Delivered</Select.Option>
              <Select.Option value="3">Progress</Select.Option>
            </Select>
          </div>
        );
      },
    },

    {
      title: <p className="table-Title">actions</p>,
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-Action">
            <div className="">
              <Space size="middle">
                <FaEye
                  size={20}
                  color="#2c698d"
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/Auth/orderTable/viewPage/${record.id}`)
                  }
                />
              </Space>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="">
        <Card className="commen-table-Card">
          <>
            <Table
              scroll={{ x: 100 }}
              columns={columns}
              dataSource={props.data}
              pagination={false}
            />
            <br />
            <div style={{ overflowY: "auto" }}>
              <Pagination
                responsive
                defaultCurrent={props.meta && props.meta.page}
                total={props.meta && props.meta.itemCount}
                pageSize={props.meta && props.meta.take ? props.meta.take : 0}
                onChange={props.PageOnchange}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </>
        </Card>
      </div>
    </>
  );
}

export default OrdersTable;
