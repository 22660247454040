import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UploadImg from "../../assets/images/Group 225.svg";
import Header from "../../component/header/header";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function HomeScreen() {
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let url = API.GET_ALL_ORDERS_BY_USERID + user.id;
      const data: any = await GET(url, null);
      setProduct(data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Header hide={true} show={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="overFlow-Box1">
        <div className="overFlow-Box2">
          <div className="white-Card" style={{ borderRadius: 0 }}>
            <Container>
              <div
                className="profile-title"
                onClick={() => navigate(`/usr/profile/${id}`)}
              >
                {t("homeScreen.title.profile")}
              </div>
              <br />
              <br />
              <Row align={"middle"} className="userScreen-Box2">
                <Col md={4}>
                  <div className="userScreen-imgBox1">
                    {user?.image ? (
                      <img
                        className="userScreen-Img"
                        src={user?.image}
                        alt=""
                      />
                    ) : (
                      <>
                        <img src={UploadImg} alt="" />
                      </>
                    )}
                  </div>
                </Col>
                <Col md={7}>
                  <div className="userScreen-Txt1">
                    {user?.name.toUpperCase()}
                  </div>
                  <div className="userScreen-Txt2 userScreen-txtColor">
                    {user?.application_no}
                  </div>
                </Col>
                <Col md={7}>
                  <div className="userScreen-Txt2">
                    {user?.countrycode} {user?.phone_no}
                  </div>
                  <div className="userScreen-Txt2">{user?.email}</div>
                  <div className="userScreen-Txt2">{user?.address}</div>
                </Col>
                <Col md={6}>
                  <div className="userScreen-Txt2">Previous Balance</div>
                  <div className="userScreen-Txt1">00 SAR</div>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <div
                className="profile-title"
                onClick={() => navigate(`/usr/profile/history/${id}`)}
              >
                {t("homeScreen.title.history")}
              </div>
              <br />
              <br />
              <Row>
                <Col md={12}>
                  {product?.map((item: any, index: any) => {
                    return (
                      <Row key={index}>
                        <Col md={12} className="screen-txt1">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/usr/orderDetails/${id}`, {
                                state: { data: item },
                              })
                            }
                          >
                            {index + 1} : {item?.productName}
                          </span>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col md={12}>
                  <Col md={{ span: 12, offset: 12 }} xs={24}>
                    <Button
                      className="primary_bttn1"
                      block
                      onClick={() => navigate(`/usr/product/${id}`)}
                    >
                      {t("homeScreen.title.products")}{" "}
                      <FiPlus size={30} color="white" />
                    </Button>
                  </Col>
                  <br />
                  <br />
                  <Col md={{ span: 12, offset: 12 }} xs={24}>
                    <Button className="primary_bttn2" block>
                      {t("homeScreen.title.service")}{" "}
                      <FiPlus size={30} color="white" />
                    </Button>
                  </Col>
                </Col>
              </Row>
              <br />
              <br />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeScreen;
