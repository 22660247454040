import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/API";
import { GET } from "../../utils/apiRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function MeasurementOption(props: any) {
  const [data, setData] = useState<any>();
  const { productId } = useParams();
  const [loding, setLoding] = useState<any>(false);
  const { accessoriesItem } = useSelector((state: any) => {
    return state;
  });
  const [accessory, setAccessory] = useState<any>([]);

  useEffect(() => {
    loadData();
    props?.onOption(accessoriesItem?.options);
    setAccessory(accessoriesItem?.options);
  }, []);

  const loadData = async () => {
    try {
      let url = API.GET_ACCESSORIES_LIST;
      const data: any = await GET(url, null);
      const filter = data?.data?.filter(
        (item: any) => item?.productid === productId
      );
      setData(filter);
    } catch (err) {
      console.error("Error loading data:", err);
    }
  };

  const addCart = async (item: any) => {
    let loading = true;
    setLoding(loading);
    const index = accessory?.findIndex(
      (item2: any) => item2?.accessories_id === item?.accessories_id
    );
    if (index == -1) {
      const updatedAccessory = [...accessory, item];
      props?.onOption(updatedAccessory);
      setAccessory(updatedAccessory);
    } else {
      let updatedAccessory = [...accessory];
      updatedAccessory[index] = item;
      props?.onOption(updatedAccessory);
      setAccessory(updatedAccessory);
    }

    setTimeout(() => {
      loading = false;
      setLoding(loading);
    }, 1);
  };

  return (
    <>
      <br />
      <br />
      <Row className="align-items-center">
        {loding
          ? null
          : data?.map((item: any) => (
              <>
                <Col
                  lg={12}
                  className="screen-text1"
                  style={{ paddingBottom: 20 }}
                >
                  {item?.name}
                </Col>

                {item?.accessories_Type.map((accessory2: any) => {
                  let selection = accessory?.find((item: any) => {
                    let data = item?.id == accessory2?.id;
                    return data ? true : false;
                  });

                  return (
                    <Col lg={3} md={4} sm={6} xs={6} key={accessory2.id}>
                      <div
                        className={
                          selection ? "screen-vectorCard2" : "screen-vectorCard"
                        }
                        onClick={() => addCart(accessory2)}
                      >
                        <img
                          key={accessory2.id}
                          className="MeasurmentScreen-Img"
                          style={{
                            height: "50px",
                            width: "50px",
                          }}
                          src={accessory2?.image}
                          alt=""
                        />
                      </div>
                      <br />
                    </Col>
                  );
                })}
                <br />
                <br />
              </>
            ))}
      </Row>
    </>
  );
}

export default MeasurementOption;
