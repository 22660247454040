import React from "react";

function Reminders() {
  return (
    <>
      <div className="blue-Card">
        <div>
          <div className="dashBoard-Txt4">REMINDERS</div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default Reminders;
